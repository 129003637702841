/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/**=====================
     01. General CSS Start
==========================**/
body {
  background-color: rgba(246, 246, 246, 0.6);
  font-size: 14px;
  overflow-x: hidden;
  font-family: Raleway !important;
  color: #333333;
}

ul {
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 0;
}

* a {
  color: #ab8ce4;
}

* a:hover {
  color: #ab8ce4;
}

*.btn:focus {
  box-shadow: none !important;
}

p {
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: 0.7px;
}

.btn {
  font-size: 14px;
  padding: 0.375rem 1.75rem;
}

code {
  color: #ab8ce4 !important;
  background-color: #eeeeee;
  padding: 3px;
  margin: 0 3px;
  border-radius: 2px;
}

blockquote {
  border-left: 4px solid #cccccc;
  padding: 15px;
}

blockquote.text-center {
  border: none;
  padding: 15px;
}

blockquote.text-right {
  border-left: none;
  border-right: 4px solid #cccccc;
  padding: 15px;
}

:focus {
  outline-color: #ab8ce4;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.ace-editor {
  height: 400px;
  width: 100%;
}

.editor-toolbar {
  width: 100% !important;
}

.CodeMirror {
  top: 0 !important;
}

.typography h1, .typography .h1,
.typography h2, .typography .h2,
.typography h3, .typography .h3,
.typography h4, .typography .h4,
.typography h5, .typography .h5,
.typography h6, .typography .h6 {
  margin: 20px 0;
  margin-top: 0;
}

.typography h1:last-child, .typography .h1:last-child,
.typography h2:last-child, .typography .h2:last-child,
.typography h3:last-child, .typography .h3:last-child,
.typography h4:last-child, .typography .h4:last-child,
.typography h5:last-child, .typography .h5:last-child,
.typography h6:last-child, .typography .h6:last-child {
  margin-bottom: 0;
}

.typography small {
  padding-left: 10px;
  color: #2a3142;
}

pre {
  background-color: #f9f7ff;
  padding: 20px;
}

a:hover {
  text-decoration: none;
}

/**=====================
     02. Generic CSS Start
==========================**/
/*====== Padding css starts ======*/
.p-0 {
  padding: 0px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

/*====== Padding css ends ======*/
/*====== Padding-left css starts ======*/
.p-l-0 {
  padding-left: 0px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-l-50 {
  padding-left: 50px;
}

/*====== Padding-left css ends ======*/
/*====== Padding-top css starts ======*/
.p-t-0 {
  padding-top: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

/*====== Padding-top css ends ======*/
/*====== Padding-bottom css starts ======*/
.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

/*====== Padding-bottom css ends ======*/
/*====== Padding-right css starts ======*/
.p-r-0 {
  padding-right: 0px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-r-50 {
  padding-right: 50px;
}

/*====== Padding-right css ends ======*/
/*====== Margin css starts ======*/
.m-0 {
  margin: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

/*====== Margin css ends ======*/
/*====== Margin-top css starts ======*/
.m-t-0 {
  margin-top: 0px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

/*====== Margin-top css ends ======*/
/*====== Margin-Bottom css starts ======*/
.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

/*====== Margin-Bottom css ends ======*/
/*====== Margin-left css starts ======*/
.m-l-0 {
  margin-left: 0px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

/*====== Margin-left css ends ======*/
/*====== Margin-right css starts ======*/
.m-r-0 {
  margin-right: 0px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-r-50 {
  margin-right: 50px;
}

/*====== Margin-right css ends ======*/
/*====== Border-radius css starts ======*/
.b-r-0 {
  border-radius: 0px !important;
}

.b-r-1 {
  border-radius: 1px !important;
}

.b-r-2 {
  border-radius: 2px !important;
}

.b-r-3 {
  border-radius: 3px !important;
}

.b-r-4 {
  border-radius: 4px !important;
}

.b-r-5 {
  border-radius: 5px !important;
}

.b-r-6 {
  border-radius: 6px !important;
}

.b-r-7 {
  border-radius: 7px !important;
}

.b-r-8 {
  border-radius: 8px !important;
}

.b-r-9 {
  border-radius: 9px !important;
}

.b-r-10 {
  border-radius: 10px !important;
}

/*====== Border-radius css ends ======*/
/*====== Font-size css starts ======*/
.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-32 {
  font-size: 32px;
}

.f-34 {
  font-size: 34px;
}

.f-36 {
  font-size: 36px;
}

.f-38 {
  font-size: 38px;
}

.f-40 {
  font-size: 40px;
}

.f-42 {
  font-size: 42px;
}

.f-44 {
  font-size: 44px;
}

.f-46 {
  font-size: 46px;
}

.f-48 {
  font-size: 48px;
}

.f-50 {
  font-size: 50px;
}

.f-52 {
  font-size: 52px;
}

.f-54 {
  font-size: 54px;
}

.f-56 {
  font-size: 56px;
}

.f-58 {
  font-size: 58px;
}

.f-60 {
  font-size: 60px;
}

.f-62 {
  font-size: 62px;
}

.f-64 {
  font-size: 64px;
}

.f-66 {
  font-size: 66px;
}

.f-68 {
  font-size: 68px;
}

.f-70 {
  font-size: 70px;
}

.f-72 {
  font-size: 72px;
}

.f-74 {
  font-size: 74px;
}

.f-76 {
  font-size: 76px;
}

.f-78 {
  font-size: 78px;
}

.f-80 {
  font-size: 80px;
}

.f-82 {
  font-size: 82px;
}

.f-84 {
  font-size: 84px;
}

.f-86 {
  font-size: 86px;
}

.f-88 {
  font-size: 88px;
}

.f-90 {
  font-size: 90px;
}

.f-92 {
  font-size: 92px;
}

.f-94 {
  font-size: 94px;
}

.f-96 {
  font-size: 96px;
}

.f-98 {
  font-size: 98px;
}

.f-100 {
  font-size: 100px;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-900 {
  font-weight: 900;
}

/*====== Font-weight css ends ======*/
/*====== Font-style css starts ======*/
.f-s-normal {
  font-style: normal;
}

.f-s-italic {
  font-style: italic;
}

.f-s-oblique {
  font-style: oblique;
}

.f-s-initial {
  font-style: initial;
}

.f-s-inherit {
  font-style: inherit;
}

/*====== Font-style css ends ======*/
/*====== Text-Decoration css starts ======*/
.text-overline {
  text-decoration: overline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-dashed {
  text-decoration: dashed;
}

.text-blink {
  text-decoration: blink;
}

.text-dotted {
  text-decoration: dotted;
}

.text-initial {
  text-decoration: initial;
}

.text-none {
  text-decoration: none;
}

.text-solid {
  text-decoration: solid;
}

.text-wavy {
  text-decoration: wavy;
}

.text-inherit {
  text-decoration: inherit;
}

.text-double {
  text-decoration: double;
}

/*====== Text-Decoration css ends ======*/
/*====== Vertical-Align css starts ======*/
.baseline {
  vertical-align: baseline;
}

.sub {
  vertical-align: sub;
}

.super {
  vertical-align: super;
}

.top {
  vertical-align: top;
}

.text-top {
  vertical-align: text-top;
}

.middle {
  vertical-align: middle;
}

.bottom {
  vertical-align: bottom;
}

.text-bottom {
  vertical-align: text-bottom;
}

.initial {
  vertical-align: initial;
}

.inherit {
  vertical-align: inherit;
}

/*====== Vertical-Align css ends ======*/
/*====== Position css starts ======*/
.p-static {
  position: static;
}

.p-absolute {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.p-relative {
  position: relative;
}

.p-initial {
  position: initial;
}

.p-inherit {
  position: inherit;
}

/*====== Position css ends ======*/
/*====== Float css starts ======*/
.f-left {
  float: left;
}

.f-right {
  float: right;
}

.f-none {
  float: none;
}

/*====== Float css ends ======*/
/*====== Overflow css starts ======*/
.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

.o-auto {
  overflow: auto;
}

/*====== Overflow css ends ======*/
/*====== Image-sizes css starts ======*/
.img-10 {
  width: 10px !important;
}

.img-20 {
  width: 20px !important;
}

.img-30 {
  width: 30px !important;
}

.img-40 {
  width: 40px !important;
}

.img-50 {
  width: 50px !important;
}

.img-60 {
  width: 60px !important;
}

.img-70 {
  width: 70px !important;
}

.img-80 {
  width: 80px !important;
}

.img-90 {
  width: 90px !important;
}

.img-100 {
  width: 100px !important;
}

/*====== Image-sizes css ends ======*/
/*======= Text css starts ===========*/
.font-primary {
  color: #ab8ce4 !important;
}

.font-secondary {
  color: #26c6da !important;
}

.font-success {
  color: #00c292 !important;
}

.font-danger {
  color: #FF5370 !important;
}

.font-info {
  color: #4099ff !important;
}

.font-light {
  color: #eeeeee !important;
}

.font-dark {
  color: #2a3142 !important;
}

.font-warning {
  color: #f3d800 !important;
}

/*======= Text css ends ===========*/
/*======= Label-color css starts  ======= */
.label {
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

.label-theme {
  background-color: #ab8ce4;
}

.label-primary {
  background-color: #ab8ce4;
}

.label-secondary {
  background-color: #26c6da;
}

.label-success {
  background-color: #00c292;
}

.label-danger {
  background-color: #FF5370;
}

.label-info {
  background-color: #4099ff;
}

.label-light {
  background-color: #eeeeee;
}

.label-dark {
  background-color: #2a3142;
}

.label-warning {
  background-color: #f3d800;
}

/*======= Label-color css ends  ======= */
/*======= Badge-color css starts  ======= */
.badge-primary {
  background-color: #ab8ce4 !important;
}

.badge-secondary {
  background-color: #26c6da !important;
}

.badge-success {
  background-color: #00c292 !important;
}

.badge-danger {
  background-color: #FF5370 !important;
}

.badge-info {
  background-color: #4099ff !important;
}

.badge-light {
  background-color: #eeeeee !important;
}

.badge-dark {
  background-color: #2a3142 !important;
}

.badge-warning {
  background-color: #f3d800 !important;
}

/*======= Badge-color css end  ======= */
/*======= Background-color css starts  ======= */
.bg-primary {
  background-color: #ab8ce4 !important;
  color: #fff;
}

.bg-secondary {
  background-color: #26c6da !important;
  color: #fff;
}

.bg-success {
  background-color: #00c292 !important;
  color: #fff;
}

.bg-danger {
  background-color: #FF5370 !important;
  color: #fff;
}

.bg-info {
  background-color: #4099ff !important;
  color: #fff;
}

.bg-light {
  background-color: #eeeeee !important;
  color: #fff;
}

.bg-dark {
  background-color: #2a3142 !important;
  color: #fff;
}

.bg-warning {
  background-color: #f3d800 !important;
  color: #fff;
}

/*======= Background-color css end  ======= */
/*======= Font-color css starts  ======= */
.txt-primary {
  color: #ab8ce4 !important;
}

.txt-secondary {
  color: #26c6da !important;
}

.txt-success {
  color: #00c292 !important;
}

.txt-danger {
  color: #FF5370 !important;
}

.txt-info {
  color: #4099ff !important;
}

.txt-light {
  color: #eeeeee !important;
}

.txt-dark {
  color: #2a3142 !important;
}

.txt-warning {
  color: #f3d800 !important;
}

.txt-google-plus {
  color: #c64e40 !important;
}

.txt-twitter {
  color: #6fa2d8 !important;
}

.txt-linkedin {
  color: #0077B5 !important;
}

.txt-fb {
  color: #50598e !important;
}

.txt-white {
  color: #fff !important;
}

/*======= Font-color css end  ======= */
/*======= Button-color css starts  ======= */
.btn-primary {
  background-color: #ab8ce4 !important;
  border-color: #ab8ce4 !important;
}

.btn-primary.disabled {
  background-color: #ab8ce4 !important;
  border-color: #ab8ce4 !important;
}

.btn-primary:disabled {
  background-color: #ab8ce4 !important;
  border-color: #ab8ce4 !important;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
  background-color: #8d63da !important;
  border-color: #8d63da !important;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem white;
}

.btn-secondary {
  background-color: #26c6da !important;
  border-color: #26c6da !important;
}

.btn-secondary.disabled {
  background-color: #26c6da !important;
  border-color: #26c6da !important;
}

.btn-secondary:disabled {
  background-color: #26c6da !important;
  border-color: #26c6da !important;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active {
  background-color: #1e9faf !important;
  border-color: #1e9faf !important;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem #a9e8f0;
}

.btn-success {
  background-color: #00c292 !important;
  border-color: #00c292 !important;
}

.btn-success.disabled {
  background-color: #00c292 !important;
  border-color: #00c292 !important;
}

.btn-success:disabled {
  background-color: #00c292 !important;
  border-color: #00c292 !important;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active {
  background-color: #008f6c !important;
  border-color: #008f6c !important;
}

.btn-success:focus {
  box-shadow: 0 0 0 0.2rem #5cffd7;
}

.btn-danger {
  background-color: #FF5370 !important;
  border-color: #FF5370 !important;
}

.btn-danger.disabled {
  background-color: #FF5370 !important;
  border-color: #FF5370 !important;
}

.btn-danger:disabled {
  background-color: #FF5370 !important;
  border-color: #FF5370 !important;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active {
  background-color: #ff2046 !important;
  border-color: #ff2046 !important;
}

.btn-danger:focus {
  box-shadow: 0 0 0 0.2rem #ffecef;
}

.btn-info {
  background-color: #4099ff !important;
  border-color: #4099ff !important;
}

.btn-info.disabled {
  background-color: #4099ff !important;
  border-color: #4099ff !important;
}

.btn-info:disabled {
  background-color: #4099ff !important;
  border-color: #4099ff !important;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active {
  background-color: #0d7eff !important;
  border-color: #0d7eff !important;
}

.btn-info:focus {
  box-shadow: 0 0 0 0.2rem #d9ebff;
}

.btn-light {
  background-color: #eeeeee !important;
  border-color: #eeeeee !important;
}

.btn-light.disabled {
  background-color: #eeeeee !important;
  border-color: #eeeeee !important;
}

.btn-light:disabled {
  background-color: #eeeeee !important;
  border-color: #eeeeee !important;
}

.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active {
  background-color: #d5d4d4 !important;
  border-color: #d5d4d4 !important;
}

.btn-light:focus {
  box-shadow: 0 0 0 0.2rem white;
}

.btn-dark {
  background-color: #2a3142 !important;
  border-color: #2a3142 !important;
}

.btn-dark.disabled {
  background-color: #2a3142 !important;
  border-color: #2a3142 !important;
}

.btn-dark:disabled {
  background-color: #2a3142 !important;
  border-color: #2a3142 !important;
}

.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active {
  background-color: #161a23 !important;
  border-color: #161a23 !important;
}

.btn-dark:focus {
  box-shadow: 0 0 0 0.2rem #67779e;
}

.btn-warning {
  background-color: #f3d800 !important;
  border-color: #f3d800 !important;
}

.btn-warning.disabled {
  background-color: #f3d800 !important;
  border-color: #f3d800 !important;
}

.btn-warning:disabled {
  background-color: #f3d800 !important;
  border-color: #f3d800 !important;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active {
  background-color: #c0ab00 !important;
  border-color: #c0ab00 !important;
}

.btn-warning:focus {
  box-shadow: 0 0 0 0.2rem #fff28d;
}

/*======= Button-color css ends  ======= */
.btn-outline-primary-2x {
  border-width: 2px;
  border-color: #ab8ce4;
  color: #ab8ce4;
  background-color: transparent;
}

.btn-outline-primary-2x:hover, .btn-outline-primary-2x:focus, .btn-outline-primary-2x:active, .btn-outline-primary-2x.active {
  color: white;
  background-color: #8d63da !important;
  border-color: #8d63da !important;
  box-shadow: none;
}

.btn-outline-secondary-2x {
  border-width: 2px;
  border-color: #26c6da;
  color: #26c6da;
  background-color: transparent;
}

.btn-outline-secondary-2x:hover, .btn-outline-secondary-2x:focus, .btn-outline-secondary-2x:active, .btn-outline-secondary-2x.active {
  color: white;
  background-color: #1e9faf !important;
  border-color: #1e9faf !important;
  box-shadow: none;
}

.btn-outline-success-2x {
  border-width: 2px;
  border-color: #00c292;
  color: #00c292;
  background-color: transparent;
}

.btn-outline-success-2x:hover, .btn-outline-success-2x:focus, .btn-outline-success-2x:active, .btn-outline-success-2x.active {
  color: white;
  background-color: #008f6c !important;
  border-color: #008f6c !important;
  box-shadow: none;
}

.btn-outline-danger-2x {
  border-width: 2px;
  border-color: #FF5370;
  color: #FF5370;
  background-color: transparent;
}

.btn-outline-danger-2x:hover, .btn-outline-danger-2x:focus, .btn-outline-danger-2x:active, .btn-outline-danger-2x.active {
  color: white;
  background-color: #ff2046 !important;
  border-color: #ff2046 !important;
  box-shadow: none;
}

.btn-outline-info-2x {
  border-width: 2px;
  border-color: #4099ff;
  color: #4099ff;
  background-color: transparent;
}

.btn-outline-info-2x:hover, .btn-outline-info-2x:focus, .btn-outline-info-2x:active, .btn-outline-info-2x.active {
  color: white;
  background-color: #0d7eff !important;
  border-color: #0d7eff !important;
  box-shadow: none;
}

.btn-outline-light-2x {
  border-width: 2px;
  border-color: #eeeeee;
  color: #eeeeee;
  background-color: transparent;
}

.btn-outline-light-2x:hover, .btn-outline-light-2x:focus, .btn-outline-light-2x:active, .btn-outline-light-2x.active {
  color: white;
  background-color: #d5d4d4 !important;
  border-color: #d5d4d4 !important;
  box-shadow: none;
}

.btn-outline-dark-2x {
  border-width: 2px;
  border-color: #2a3142;
  color: #2a3142;
  background-color: transparent;
}

.btn-outline-dark-2x:hover, .btn-outline-dark-2x:focus, .btn-outline-dark-2x:active, .btn-outline-dark-2x.active {
  color: white;
  background-color: #161a23 !important;
  border-color: #161a23 !important;
  box-shadow: none;
}

.btn-outline-warning-2x {
  border-width: 2px;
  border-color: #f3d800;
  color: #f3d800;
  background-color: transparent;
}

.btn-outline-warning-2x:hover, .btn-outline-warning-2x:focus, .btn-outline-warning-2x:active, .btn-outline-warning-2x.active {
  color: white;
  background-color: #c0ab00 !important;
  border-color: #c0ab00 !important;
  box-shadow: none;
}

.btn-outline-primary {
  border-color: #ab8ce4;
  color: #ab8ce4;
  background-color: transparent;
}

.btn-outline-primary.disabled {
  color: #ab8ce4;
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active {
  color: white;
  background-color: #8d63da !important;
  border-color: #8d63da !important;
}

.btn-outline-secondary {
  border-color: #26c6da;
  color: #26c6da;
  background-color: transparent;
}

.btn-outline-secondary.disabled {
  color: #26c6da;
}

.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active {
  color: white;
  background-color: #1e9faf !important;
  border-color: #1e9faf !important;
}

.btn-outline-success {
  border-color: #00c292;
  color: #00c292;
  background-color: transparent;
}

.btn-outline-success.disabled {
  color: #00c292;
}

.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active {
  color: white;
  background-color: #008f6c !important;
  border-color: #008f6c !important;
}

.btn-outline-danger {
  border-color: #FF5370;
  color: #FF5370;
  background-color: transparent;
}

.btn-outline-danger.disabled {
  color: #FF5370;
}

.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active {
  color: white;
  background-color: #ff2046 !important;
  border-color: #ff2046 !important;
}

.btn-outline-info {
  border-color: #4099ff;
  color: #4099ff;
  background-color: transparent;
}

.btn-outline-info.disabled {
  color: #4099ff;
}

.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active {
  color: white;
  background-color: #0d7eff !important;
  border-color: #0d7eff !important;
}

.btn-outline-light {
  border-color: #eeeeee;
  color: #eeeeee;
  background-color: transparent;
  color: #2a3142;
}

.btn-outline-light.disabled {
  color: #eeeeee;
}

.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active, .btn-outline-light.active {
  color: white;
  background-color: #d5d4d4 !important;
  border-color: #d5d4d4 !important;
}

.btn-outline-dark {
  border-color: #2a3142;
  color: #2a3142;
  background-color: transparent;
}

.btn-outline-dark.disabled {
  color: #2a3142;
}

.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active {
  color: white;
  background-color: #161a23 !important;
  border-color: #161a23 !important;
}

.btn-outline-warning {
  border-color: #f3d800;
  color: #f3d800;
  background-color: transparent;
}

.btn-outline-warning.disabled {
  color: #f3d800;
}

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active {
  color: white;
  background-color: #c0ab00 !important;
  border-color: #c0ab00 !important;
}

.btn-primary:not([disabled]):not(.disabled).active {
  background-color: #8d63da;
  border-color: #8d63da;
  box-shadow: none !important;
}

.btn-primary:not([disabled]):not(.disabled).active:hover, .btn-primary:not([disabled]):not(.disabled).active:focus, .btn-primary:not([disabled]):not(.disabled).active:active, .btn-primary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #8d63da;
  border-color: #8d63da;
}

.btn-secondary:not([disabled]):not(.disabled).active {
  background-color: #1e9faf;
  border-color: #1e9faf;
  box-shadow: none !important;
}

.btn-secondary:not([disabled]):not(.disabled).active:hover, .btn-secondary:not([disabled]):not(.disabled).active:focus, .btn-secondary:not([disabled]):not(.disabled).active:active, .btn-secondary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #1e9faf;
  border-color: #1e9faf;
}

.btn-success:not([disabled]):not(.disabled).active {
  background-color: #008f6c;
  border-color: #008f6c;
  box-shadow: none !important;
}

.btn-success:not([disabled]):not(.disabled).active:hover, .btn-success:not([disabled]):not(.disabled).active:focus, .btn-success:not([disabled]):not(.disabled).active:active, .btn-success:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #008f6c;
  border-color: #008f6c;
}

.btn-danger:not([disabled]):not(.disabled).active {
  background-color: #ff2046;
  border-color: #ff2046;
  box-shadow: none !important;
}

.btn-danger:not([disabled]):not(.disabled).active:hover, .btn-danger:not([disabled]):not(.disabled).active:focus, .btn-danger:not([disabled]):not(.disabled).active:active, .btn-danger:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #ff2046;
  border-color: #ff2046;
}

.btn-info:not([disabled]):not(.disabled).active {
  background-color: #0d7eff;
  border-color: #0d7eff;
  box-shadow: none !important;
}

.btn-info:not([disabled]):not(.disabled).active:hover, .btn-info:not([disabled]):not(.disabled).active:focus, .btn-info:not([disabled]):not(.disabled).active:active, .btn-info:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #0d7eff;
  border-color: #0d7eff;
}

.btn-light:not([disabled]):not(.disabled).active {
  background-color: #d5d4d4;
  border-color: #d5d4d4;
  box-shadow: none !important;
}

.btn-light:not([disabled]):not(.disabled).active:hover, .btn-light:not([disabled]):not(.disabled).active:focus, .btn-light:not([disabled]):not(.disabled).active:active, .btn-light:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #d5d4d4;
  border-color: #d5d4d4;
}

.btn-dark:not([disabled]):not(.disabled).active {
  background-color: #161a23;
  border-color: #161a23;
  box-shadow: none !important;
}

.btn-dark:not([disabled]):not(.disabled).active:hover, .btn-dark:not([disabled]):not(.disabled).active:focus, .btn-dark:not([disabled]):not(.disabled).active:active, .btn-dark:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #161a23;
  border-color: #161a23;
}

.btn-warning:not([disabled]):not(.disabled).active {
  background-color: #c0ab00;
  border-color: #c0ab00;
  box-shadow: none !important;
}

.btn-warning:not([disabled]):not(.disabled).active:hover, .btn-warning:not([disabled]):not(.disabled).active:focus, .btn-warning:not([disabled]):not(.disabled).active:active, .btn-warning:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #c0ab00;
  border-color: #c0ab00;
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active {
  background-color: #ab8ce4;
  border-color: #ab8ce4;
  box-shadow: none !important;
  color: white;
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-primary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-primary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-primary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #8d63da !important;
  border-color: #8d63da !important;
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active {
  background-color: #26c6da;
  border-color: #26c6da;
  box-shadow: none !important;
  color: white;
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #1e9faf !important;
  border-color: #1e9faf !important;
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active {
  background-color: #00c292;
  border-color: #00c292;
  box-shadow: none !important;
  color: white;
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-success-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-success-2x:not([disabled]):not(.disabled).active:active, .btn-outline-success-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #008f6c !important;
  border-color: #008f6c !important;
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active {
  background-color: #FF5370;
  border-color: #FF5370;
  box-shadow: none !important;
  color: white;
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-danger-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-danger-2x:not([disabled]):not(.disabled).active:active, .btn-outline-danger-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #ff2046 !important;
  border-color: #ff2046 !important;
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active {
  background-color: #4099ff;
  border-color: #4099ff;
  box-shadow: none !important;
  color: white;
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-info-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-info-2x:not([disabled]):not(.disabled).active:active, .btn-outline-info-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #0d7eff !important;
  border-color: #0d7eff !important;
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active {
  background-color: #eeeeee;
  border-color: #eeeeee;
  box-shadow: none !important;
  color: white;
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-light-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-light-2x:not([disabled]):not(.disabled).active:active, .btn-outline-light-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #d5d4d4 !important;
  border-color: #d5d4d4 !important;
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active {
  background-color: #2a3142;
  border-color: #2a3142;
  box-shadow: none !important;
  color: white;
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-dark-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-dark-2x:not([disabled]):not(.disabled).active:active, .btn-outline-dark-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #161a23 !important;
  border-color: #161a23 !important;
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active {
  background-color: #f3d800;
  border-color: #f3d800;
  box-shadow: none !important;
  color: white;
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-warning-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-warning-2x:not([disabled]):not(.disabled).active:active, .btn-outline-warning-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  box-shadow: none !important;
  background-color: #c0ab00 !important;
  border-color: #c0ab00 !important;
}

/*======= Table-Border-Bottom-color css starts  ======= */
table thead .border-bottom-primary th,
table tbody .border-bottom-primary th,
table tbody .border-bottom-primary td {
  border-bottom: 1px solid #ab8ce4;
}

table thead .border-bottom-secondary th,
table tbody .border-bottom-secondary th,
table tbody .border-bottom-secondary td {
  border-bottom: 1px solid #26c6da;
}

table thead .border-bottom-success th,
table tbody .border-bottom-success th,
table tbody .border-bottom-success td {
  border-bottom: 1px solid #00c292;
}

table thead .border-bottom-danger th,
table tbody .border-bottom-danger th,
table tbody .border-bottom-danger td {
  border-bottom: 1px solid #FF5370;
}

table thead .border-bottom-info th,
table tbody .border-bottom-info th,
table tbody .border-bottom-info td {
  border-bottom: 1px solid #4099ff;
}

table thead .border-bottom-light th,
table tbody .border-bottom-light th,
table tbody .border-bottom-light td {
  border-bottom: 1px solid #eeeeee;
}

table thead .border-bottom-dark th,
table tbody .border-bottom-dark th,
table tbody .border-bottom-dark td {
  border-bottom: 1px solid #2a3142;
}

table thead .border-bottom-warning th,
table tbody .border-bottom-warning th,
table tbody .border-bottom-warning td {
  border-bottom: 1px solid #f3d800;
}

/*======= Table-Border-Bottom-color css ends  ======= */
/*======= Table styling css starts  ======= */
.table-styling .table-primary,
.table-styling.table-primary {
  background-color: #ab8ce4;
  color: #fff;
  border: 3px solid #ab8ce4;
}

.table-styling .table-primary thead,
.table-styling.table-primary thead {
  background-color: #8d63da;
  border: 3px solid #8d63da;
}

.table-styling .table-secondary,
.table-styling.table-secondary {
  background-color: #26c6da;
  color: #fff;
  border: 3px solid #26c6da;
}

.table-styling .table-secondary thead,
.table-styling.table-secondary thead {
  background-color: #1e9faf;
  border: 3px solid #1e9faf;
}

.table-styling .table-success,
.table-styling.table-success {
  background-color: #00c292;
  color: #fff;
  border: 3px solid #00c292;
}

.table-styling .table-success thead,
.table-styling.table-success thead {
  background-color: #008f6c;
  border: 3px solid #008f6c;
}

.table-styling .table-danger,
.table-styling.table-danger {
  background-color: #FF5370;
  color: #fff;
  border: 3px solid #FF5370;
}

.table-styling .table-danger thead,
.table-styling.table-danger thead {
  background-color: #ff2046;
  border: 3px solid #ff2046;
}

.table-styling .table-info,
.table-styling.table-info {
  background-color: #4099ff;
  color: #fff;
  border: 3px solid #4099ff;
}

.table-styling .table-info thead,
.table-styling.table-info thead {
  background-color: #0d7eff;
  border: 3px solid #0d7eff;
}

.table-styling .table-light,
.table-styling.table-light {
  background-color: #eeeeee;
  color: #fff;
  border: 3px solid #eeeeee;
}

.table-styling .table-light thead,
.table-styling.table-light thead {
  background-color: #d5d4d4;
  border: 3px solid #d5d4d4;
}

.table-styling .table-dark,
.table-styling.table-dark {
  background-color: #2a3142;
  color: #fff;
  border: 3px solid #2a3142;
}

.table-styling .table-dark thead,
.table-styling.table-dark thead {
  background-color: #161a23;
  border: 3px solid #161a23;
}

.table-styling .table-warning,
.table-styling.table-warning {
  background-color: #f3d800;
  color: #fff;
  border: 3px solid #f3d800;
}

.table-styling .table-warning thead,
.table-styling.table-warning thead {
  background-color: #c0ab00;
  border: 3px solid #c0ab00;
}

/*======= Table styling css ends  ======= */
/*======= All-Borders-color css starts  ======= */
.b-primary {
  border: 1px solid #ab8ce4 !important;
}

.b-t-primary {
  border-top: 1px solid #ab8ce4 !important;
}

.b-b-primary {
  border-bottom: 1px solid #ab8ce4 !important;
}

.b-l-primary {
  border-left: 1px solid #ab8ce4 !important;
}

.b-r-primary {
  border-right: 1px solid #ab8ce4 !important;
}

.b-secondary {
  border: 1px solid #26c6da !important;
}

.b-t-secondary {
  border-top: 1px solid #26c6da !important;
}

.b-b-secondary {
  border-bottom: 1px solid #26c6da !important;
}

.b-l-secondary {
  border-left: 1px solid #26c6da !important;
}

.b-r-secondary {
  border-right: 1px solid #26c6da !important;
}

.b-success {
  border: 1px solid #00c292 !important;
}

.b-t-success {
  border-top: 1px solid #00c292 !important;
}

.b-b-success {
  border-bottom: 1px solid #00c292 !important;
}

.b-l-success {
  border-left: 1px solid #00c292 !important;
}

.b-r-success {
  border-right: 1px solid #00c292 !important;
}

.b-danger {
  border: 1px solid #FF5370 !important;
}

.b-t-danger {
  border-top: 1px solid #FF5370 !important;
}

.b-b-danger {
  border-bottom: 1px solid #FF5370 !important;
}

.b-l-danger {
  border-left: 1px solid #FF5370 !important;
}

.b-r-danger {
  border-right: 1px solid #FF5370 !important;
}

.b-info {
  border: 1px solid #4099ff !important;
}

.b-t-info {
  border-top: 1px solid #4099ff !important;
}

.b-b-info {
  border-bottom: 1px solid #4099ff !important;
}

.b-l-info {
  border-left: 1px solid #4099ff !important;
}

.b-r-info {
  border-right: 1px solid #4099ff !important;
}

.b-light {
  border: 1px solid #eeeeee !important;
}

.b-t-light {
  border-top: 1px solid #eeeeee !important;
}

.b-b-light {
  border-bottom: 1px solid #eeeeee !important;
}

.b-l-light {
  border-left: 1px solid #eeeeee !important;
}

.b-r-light {
  border-right: 1px solid #eeeeee !important;
}

.b-dark {
  border: 1px solid #2a3142 !important;
}

.b-t-dark {
  border-top: 1px solid #2a3142 !important;
}

.b-b-dark {
  border-bottom: 1px solid #2a3142 !important;
}

.b-l-dark {
  border-left: 1px solid #2a3142 !important;
}

.b-r-dark {
  border-right: 1px solid #2a3142 !important;
}

.b-warning {
  border: 1px solid #f3d800 !important;
}

.b-t-warning {
  border-top: 1px solid #f3d800 !important;
}

.b-b-warning {
  border-bottom: 1px solid #f3d800 !important;
}

.b-l-warning {
  border-left: 1px solid #f3d800 !important;
}

.b-r-warning {
  border-right: 1px solid #f3d800 !important;
}

/*======= All-Borders-color css ends  ======= */
/*====== Border width css starts ======*/
.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-6 {
  border-width: 6px !important;
}

.border-7 {
  border-width: 7px !important;
}

.border-8 {
  border-width: 8px !important;
}

.border-9 {
  border-width: 9px !important;
}

.border-10 {
  border-width: 10px !important;
}

/*====== Border width css ends ======*/
.card {
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  border: 0;
  transition: all 0.3s ease;
}

.card:hover {
  transition: all 0.3s ease;
  box-shadow: 0 1px 11px 0 rgba(0, 0, 0, 0.12);
}

.card .card-header {
  background-color: #fff;
  border-bottom: 1px solid #eeeeee;
  padding: 30px;
}

.card .card-header h5 {
  font-size: 18px;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 600;
}

.card .card-header > span {
  font-size: 12px;
  margin-top: 5px;
  display: block;
  letter-spacing: 1px;
  color: #777777;
}

.card .card-body {
  padding: 30px;
}

.card .card-body p:last-child {
  margin-bottom: 0;
}

.card .sub-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 5px;
  margin-bottom: 8px;
  font-size: 18px;
}

.card .card-footer {
  background-color: #fff;
  border-top: 1px solid #eeeeee;
  padding: 30px;
}

.opacity-0 {
  opacity: 0;
}

.shadow-0 {
  box-shadow: none;
}

.map-block {
  height: 350px;
  width: 100%;
}

/**====== custom scrollbar css start ======**/
.digits {
  font-family: Open Sans;
}

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #cccccc;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cccccc;
}

/**====== Custom scrollbar css end ======**/
/**====== Animation css Start ======**/
.comment {
  color: #9f9ba5;
  font-style: italic;
}

.line {
  color: #2a3142;
}

.line pre {
  font-size: 100%;
}

/**====== Animation css end ======**/
/**====== list style css Start ======**/
.list-circle {
  list-style: circle;
}

/**====== list style css end ======**/
/**====== Modal style css Start ======**/
.theme-close {
  opacity: 1;
  height: 40px;
  width: 40px;
  position: absolute;
  z-index: 1;
  right: 0;
  background-color: #fff !important;
  border-radius: 5px;
}

/**====== Modal style css end ======**/
/**====== Animation css start ======**/
.options > div {
  color: #999999;
  display: inline-block;
  padding: 2px 10px;
  border: 1px solid;
  margin: 0 8px 8px 0;
  transition: all 0.3s ease;
}

.options > div:hover {
  background-color: #999999;
  color: #fff;
  transition: all 0.3s ease;
}

/**====== Animation css Ends ======**/
.modal-footer {
  flex-wrap: wrap;
}

.img-cropper #putData {
  margin-bottom: 0;
}

.img-cropper .img-container {
  min-height: auto;
  margin-bottom: 0;
}

.img-cropper .docs-data > .input-group:last-child {
  margin-bottom: 0;
}

.img-cropper .docs-preview {
  margin-top: 20px;
  margin-bottom: 10px;
}

.bootstrap-datetimepicker-widget {
  font-family: Open Sans;
}

.dropzone .dz-preview .dz-error-message {
  background: #fff !important;
  color: #ab8ce4 !important;
  border: 1px solid #ab8ce4;
}

.dropzone .dz-preview .dz-error-message:after {
  border-bottom: 6px solid #ab8ce4 !important;
}

.typeahead .theme-form .form-group {
  margin-bottom: 0;
}

.editor-statusbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.page-builder .ge-canvas.ge-editing .row {
  padding: 30px;
  margin-bottom: 0;
}

.page-builder .ge-canvas.ge-layout-desktop [class*="col-"] {
  width: inherit !important;
}

.page-builder .btn-screen {
  padding: 0 18px 0 0;
}

.datepicker {
  z-index: 99;
}

/**=====================
     02. Generic CSS Ends
==========================**/
/**=====================
    03.  Card CSS Start
==========================**/
.card .card-header .card-header-right {
  border-radius: 0 0 0 7px;
  right: 20px;
  top: 23px;
  display: inline-block;
  float: right;
  padding: 7px 0;
  position: absolute;
}

.card .card-header .card-header-right .card-option {
  text-align: right;
  width: 35px;
  height: 20px;
  overflow: hidden;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.card .card-header .card-header-right .card-option li {
  display: inline-block;
}

.card .card-header .card-header-right i {
  margin: 0 5px;
  cursor: pointer;
  color: #2a3142;
  line-height: 20px;
}

.card .card-header .card-header-right i.icofont-refresh {
  font-size: 13px;
}

.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  align-items: center;
  justify-content: center;
}

.card.card-load .card-loader i {
  margin: 0 auto;
  color: #ab8ce4;
  font-size: 20px;
}

.card.full-card {
  position: fixed;
  top: 80px;
  z-index: 99999;
  -webkit-box-shadow: none;
  box-shadow: none;
  right: 0;
  border-radius: 0;
  border: 1px solid #ddd;
  width: calc(100vw - 270px);
  height: calc(100vh - 80px);
}

.card-absolute {
  margin-top: 20px;
}

.card-absolute .card-header {
  position: absolute;
  top: -20px;
  margin-bottom: 30px;
  left: 15px;
  border-radius: 0.25rem;
  padding: 10px 15px;
}

.card-absolute .card-header h5 {
  font-size: 17px;
}

.card-absolute .card-body {
  margin-top: 10px;
}

.card-header .nav-material {
  margin-bottom: -13px;
}

.custom-card {
  overflow: hidden;
  padding: 30px;
}

.custom-card .card-header {
  padding: 0;
}

.custom-card .card-header img {
  border-radius: 50%;
  margin-top: -100px;
  transform: scale(1.5);
}

.custom-card .card-profile {
  text-align: center;
}

.custom-card .card-profile img {
  height: 150px;
  padding: 7px;
  background-color: #fff;
  z-index: 1;
  position: relative;
}

.custom-card .card-social {
  text-align: center;
}

.custom-card .card-social li {
  display: inline-block;
  padding: 15px 0;
}

.custom-card .card-social li a {
  padding: 13px;
  color: #ddd;
  font-size: 16px;
  transition: all 0.3s ease;
}

.custom-card .card-social li a:hover {
  color: #ab8ce4;
  transition: all 0.3s ease;
}

.custom-card .profile-details h6 {
  margin-bottom: 30px;
  margin-top: 10px;
  color: #777777;
  font-size: 14px;
}

.custom-card .card-footer {
  padding: 0;
}

.custom-card .card-footer > div {
  padding: 15px;
  text-align: center;
}

.custom-card .card-footer > div + div {
  border-left: 1px solid #ddd;
}

.custom-card .card-footer > div h3 {
  margin-bottom: 0;
  font-family: Open Sans;
  font-size: 24px;
}

.custom-card .card-footer > div h6 {
  font-size: 14px;
  color: #777777;
}

.custom-card .card-footer > div i {
  font-size: 24px;
  display: inline-block;
  margin-bottom: 15px;
}

.custom-card .card-footer > div .m-b-card {
  margin-bottom: 10px;
}

/**=====================
    03. Card CSS End
==========================**/
/**=====================
     04. Loader CSS Start
==========================**/
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: #fff;
  z-index: 11;
  top: 0;
}

.loader-wrapper .loader {
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  right: 0;
  margin: 0 auto;
}

.loader-wrapper .loader h4 {
  margin-top: 50px;
  margin-bottom: 0;
}

.loader-wrapper .loader h4 span {
  font-size: 34px;
  color: #FFC107;
}

.loader .line {
  animation: expand 1s ease-in-out infinite;
  border-radius: 10px;
  display: inline-block;
  transform-origin: center center;
  margin: 0 3px;
  width: 1px;
  height: 25px;
}

.loader .line:nth-child(1) {
  background: #00c292;
}

.loader .line:nth-child(2) {
  animation-delay: 180ms;
  background: #ab8ce4;
}

.loader .line:nth-child(3) {
  animation-delay: 360ms;
  background: #FF5370;
}

.loader .line:nth-child(4) {
  animation-delay: 540ms;
  background: #4099ff;
}

@keyframes expand {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
}

.loader-box {
  height: 150px;
  text-align: center;
  display: flex;
  align-items: center;
}

.rotate {
  animation-timing-function: linear;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: rotate;
  border: 3px dotted #ab8ce4;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: block;
  margin: 1em;
  float: left;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.solid {
  border-style: solid;
  border-color: #00c292 #008f6c;
}

.dotted {
  border-style: dotted;
}

.dashed {
  border-style: dashed;
}

.double {
  border-style: double;
}

.groove {
  border-style: groove;
}

.ridge {
  border-style: ridge;
}

.inset {
  border-style: inset;
}

.outset {
  border-style: outset;
}

.colored {
  border-color: #FF5370 #ab8ce4 #00c292 #4099ff;
}

/**=====================
     04. Loader CSS Ends
==========================**/
/**=====================
    05. Header CSS Start
==========================**/
.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li:hover > a, .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li.active > a {
  color: #000;
  border-left-color: #2a3142;
  transition: all 0.3s ease;
}

@keyframes sidebar-menu-open {
  0% {
    transform: translate(0px, -50%) scale(1, 0);
  }
  100% {
    transform: translate(0px, 0%) scale(1, 1);
  }
}

.onhover-show-div {
  box-shadow: 0 0 2px 2px #eeeeee;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}

.onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
}

.onhover-dropdown:hover .onhover-show-div:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  content: "";
  top: -7px;
  position: absolute;
  left: 10px;
  z-index: 2;
}

.onhover-dropdown:hover .onhover-show-div:after {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #717171;
  content: "";
  top: -7px;
  position: absolute;
  left: 10px;
  z-index: 1;
}

/**======Main Header css Start ======**/
.page-main-header {
  max-width: 100vw;
}

.page-main-header .main-header-left {
  width: 280px;
  display: inline-flex;
  height: 100%;
  padding: 12px;
  align-items: center;
  background-color: #fff;
  z-index: 9;
}

.page-main-header .main-header-left .logo-wrapper {
  display: block;
  position: relative;
}

.page-main-header .main-header-left .logo-wrapper img {
  height: 65px;
  margin-top: 10px;
}

.page-main-header .main-header-left .logo-wrapper a .image-dark {
  display: block;
}

.page-main-header .main-header-left .logo-wrapper a .image-light {
  display: none;
}

.page-main-header .main-header-left .mobile-sidebar {
  display: inline-block;
}

.page-main-header .main-header-left .mobile-sidebar i {
  color: #ab8ce4;
  font-size: 20px;
  margin-left: 30px;
}

.page-main-header .main-header-left .mobile-sidebar .switch {
  margin-bottom: 0;
  width: 30px;
  height: 17px;
  margin-left: 40px;
  margin-top: 10px;
}

.page-main-header .main-header-left .mobile-sidebar .switch .switch-state:before {
  height: 11px;
  width: 11px;
  background-color: #ffffff;
}

.page-main-header .main-header-left .mobile-sidebar input:checked + .switch-state:before {
  left: -10px;
}

.page-main-header .main-header-right {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 40px;
}

.page-main-header .main-header-right .switch-state {
  background-color: transparent;
  border: 1px solid #ab8ce4;
}

.page-main-header .main-header-right .switch-sm .switch .switch-state:before {
  bottom: 3px;
  background-color: #ab8ce4;
}

.page-main-header .main-header-right .switch input:checked + .switch-state:before {
  background-color: #fff;
}

.page-main-header .main-header-right .nav-left i {
  margin-right: 20px;
}

.page-main-header .main-header-right .nav-left input:focus {
  outline: 0 !important;
}

.page-main-header .main-header-right .nav-right {
  text-align: right;
}

.page-main-header .main-header-right .nav-right ul li i {
  font-size: 20px;
}

.page-main-header .main-header-right .nav-right .notification {
  position: absolute;
  top: 21px;
  right: -1px;
  font-size: 9px;
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.page-main-header .main-header-right .nav-right .icon-user {
  font-size: 16px;
}

@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0.1);
    opacity: .1;
  }
  50% {
    transform: scale(0.5);
    opacity: .3;
  }
  75% {
    transform: scale(0.8);
    opacity: .5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.page-main-header .main-header-right .nav-right > ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.page-main-header .main-header-right .nav-right > ul > li {
  margin-left: 20px;
  position: relative;
  padding: 30px 0;
}

.page-main-header .main-header-right .nav-right > ul > li h6 {
  color: #ab8ce4;
}

.page-main-header .main-header-right .nav-right > ul > li h6 ul {
  left: inherit;
  right: -10px;
  width: 130px;
}

.page-main-header .main-header-right .nav-right > ul > li h6 ul:before, .page-main-header .main-header-right .nav-right > ul > li h6 ul:after {
  left: inherit;
  right: 10px;
}

.page-main-header .main-header-right .nav-right > ul > li h6 ul li {
  display: block;
}

.page-main-header .main-header-right .nav-right > ul > li h6 ul li a {
  font-size: 14px;
  color: #2a3142;
}

.page-main-header .main-header-right .nav-right > ul > li h6 ul li a i {
  margin-left: 10px;
  font-size: 13px;
  color: #2a3142;
}

.page-main-header .main-header-right .nav-right > ul .flag-icon {
  font-size: 16px;
}

.page-main-header .main-header-right .nav-right .language-dropdown {
  width: 160px;
  text-align: left;
}

.page-main-header .main-header-right .nav-right .language-dropdown li {
  padding-top: 10px;
}

.page-main-header .main-header-right .nav-right .language-dropdown li a {
  color: #2a3142;
}

.page-main-header .main-header-right .nav-right .language-dropdown li a i {
  margin-right: 10px;
}

.page-main-header .main-header-right .nav-right .language-dropdown li:first-child {
  padding-top: 0;
}

.page-main-header .main-header-right .nav-right .profile-dropdown {
  right: -10px;
  left: inherit;
  width: 150px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown:before, .page-main-header .main-header-right .nav-right .profile-dropdown:after {
  left: inherit;
  right: 10px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li {
  display: block;
  text-align: left;
  padding-top: 10px;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:first-child {
  padding-top: 0;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li:hover a {
  color: #ab8ce4;
  transition: all 0.3s ease;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li a {
  color: #2a3142;
  transition: all 0.3s ease;
}

.page-main-header .main-header-right .nav-right .profile-dropdown li i {
  font-size: 16px;
  margin-right: 10px;
}

.page-main-header .main-header-right li {
  display: inline-block;
  position: relative;
}

.onhover-dropdown {
  cursor: pointer;
  position: relative;
}

ul.notification-dropdown.onhover-show-div {
  width: 330px;
  right: -30px;
  left: initial;
}

ul.notification-dropdown.onhover-show-div:before, ul.notification-dropdown.onhover-show-div:after {
  left: inherit !important;
  right: 35px !important;
}

ul.notification-dropdown.onhover-show-div li {
  display: block;
  padding: 12px 20px;
  border-bottom: 1px solid #eeeeee;
  text-align: left;
}

ul.notification-dropdown.onhover-show-div li span {
  font-size: 12px;
  color: #2a3142;
  font-family: Open Sans;
}

ul.notification-dropdown.onhover-show-div li span i {
  font-size: 11px !important;
  color: #2a3142;
}

ul.notification-dropdown.onhover-show-div li .notification-icon {
  padding: 15px;
  border-radius: 100%;
  margin-right: 20px;
}

.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 9;
  background-color: #fff;
  transition: all linear 0.3s;
}

/**======Main Header css Ends ======**/
/*======= Page Header css Start ======= */
.page-wrapper .page-main-header {
  background-color: #ffffff;
  height: 80px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  box-shadow: 0 0 1px 1px #eeeeee;
}

.page-wrapper .page-body-wrapper.sidebar-icon.sidebar-close .page-sidebar-open {
  left: -150px;
  transition: all 0.5s ease;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar ~ .page-body {
  margin-left: 150px;
  width: 100%;
}

.page-wrapper .page-body-wrapper .page-sidebar {
  width: 255px;
  position: fixed;
  background: #ffffff;
  top: 80px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
  height: calc(100vh - 80px);
  overflow: auto;
  z-index: 9;
  transform: translate(0px);
  transition: 0.5s;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user {
  background-color: #f6f7fb;
  padding: 25px 10px;
  box-shadow: 3px 2px 8px -1px rgba(0, 0, 0, 0.13);
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user img {
  border: 3px solid #fff;
  box-shadow: 0px 5px 22px 0px rgba(0, 0, 0, 0.2);
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user h6 {
  color: #2a3142;
  position: relative;
  text-transform: uppercase;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user h6 i {
  margin-right: 10px;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user p {
  color: #fff;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user .profile-dropdown {
  top: 44px;
  right: 8px;
  left: inherit;
  width: 130px;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user .profile-dropdown:before, .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user .profile-dropdown:after {
  left: inherit;
  right: 10px;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user .profile-dropdown li {
  display: block;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user .profile-dropdown li a {
  padding-bottom: 10px;
  display: block;
  color: #2a3142;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-user .profile-dropdown li:last-child a {
  padding-bottom: 0;
}

.page-wrapper .page-body-wrapper .page-sidebar ~ .page-body {
  margin-left: 250px;
  transition: 0.5s;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 20px;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-title {
  text-transform: uppercase;
  font-weight: 600;
  color: #ab8ce4;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 5px;
  letter-spacing: 1px;
  font-size: 14px;
  margin-top: 25px;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header {
  font-size: 13px;
  letter-spacing: 1px;
  padding-bottom: 7px;
  padding-top: 7px;
  text-transform: uppercase;
  font-weight: 500;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a {
  display: block;
  color: #2a3142;
  font-size: 14px;
  transition: all 0.3s ease;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a.active {
  color: #ab8ce4;
  transition: all 0.3s ease;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a:hover {
  color: #ab8ce4;
  padding-left: 5px;
  transition: all 0.3s ease;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a i {
  margin-right: 7px;
  text-align: right;
  margin-top: 3px;
  font-size: 15px;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a i ~ i {
  margin-right: 0;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li .label {
  margin-top: 3px;
  margin-right: 5px;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li .badge {
  margin-top: 3px;
  margin-right: 5px;
  text-transform: capitalize;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.sidebar-header {
  margin-bottom: 0;
  padding: 15px;
  color: #ab8ce4;
  font-weight: 600;
  transition: all 0.3s ease;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active > a > .fa-angle-right {
  transform: rotate(90deg);
  transition: all 0.3s ease;
  vertical-align: -7px;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li.active > .sidebar-submenu {
  display: block;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  margin-left: 5px;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu li > a > .fa-angle-down {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
  margin-top: 3px;
  transition: all 0.3s ease;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu a {
  color: #2a3142;
  text-decoration: none;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu {
  display: none;
  animation: .3s alternate forwards sidebar-menu-open;
  list-style: none;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu .sidebar-submenu {
  padding-left: 20px;
  padding-top: 0 !important;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a {
  padding-bottom: 7px;
  padding-top: 7px;
  display: block;
  font-size: 14px;
  color: #333333;
  transition: all 0.3s ease;
  text-transform: capitalize;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a > .fa {
  width: 20px;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a > i {
  width: auto;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a > .fa-angle-down {
  width: auto;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a:hover {
  color: #ab8ce4;
  transition: all 0.3s ease;
  padding-left: 5px;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a.active {
  color: #ab8ce4;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li.active > a {
  color: #2a3142;
}

.page-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 80px);
  margin-top: 80px;
  padding: 0 20px;
  position: relative;
  background-color: #f6f7fb;
}

.page-wrapper .page-body-wrapper .page-header {
  padding-top: 30px;
  padding-bottom: 30px;
}

.page-wrapper .page-body-wrapper .page-header .row {
  align-items: center;
}

.page-wrapper .page-body-wrapper .page-header .row h3 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 600;
  text-transform: uppercase;
}

.page-wrapper .page-body-wrapper .page-header .row h3 small {
  display: block;
  font-size: 12px;
  margin-top: 7px;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: #777777;
}

.page-wrapper .page-body-wrapper .page-header .breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
}

.page-wrapper .sidebar-close .page-sidebar {
  transition: 0.5s;
  transform: translate(-255px);
}

.page-wrapper .sidebar-close .page-body {
  transition: 0.5s;
  margin-left: 0 !important;
}

/*======= Page Header css ends  ======= */
ul .submenu {
  width: 0;
  overflow: hidden;
  list-style-type: none;
  background: #fff;
  transition: width 0.5s ease-out;
  position: absolute;
  top: -8px;
  right: -12px;
}

ul .submenu input {
  padding: 6px 12px;
  width: 200px;
}

.scorlled .page-sidebar {
  top: 0 !important;
  height: 100vh !important;
}

/** ===== Vertical Menu css Start=====**/
.page-wrapper .vertical-menu-main {
  position: fixed;
  top: 80px;
  width: 100%;
  z-index: 9;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.vertical-menu-mt {
  margin-top: 133px !important;
}

.vertical-menu-mt .custom-card .card-header img {
  margin-top: -146px !important;
}

.vertical-menu-main #main-nav #main-menu li a i {
  margin-right: 8px;
  font-size: 15px;
}

.vertical-menu-main .mega-menu {
  width: 1500px !important;
  max-width: 1500px !important;
  padding: 0;
  left: -182px !important;
}

/** ===== Vertical Menu css Ends=====**/
/* ========sidebar icon vertical menu start=======*/
.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu li:hover .single-header, .page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu li:hover .sidebar-submenu, .page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu li a:hover + ul {
  display: block;
  transform: rotateX(0deg);
  z-index: 1;
  background-color: #fff;
  color: #717171;
  font-size: 14px;
  opacity: 1;
  margin-top: -70px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
  position: absolute;
  width: 245px;
  margin-left: 115px;
}

.compact-switch {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-icon {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
}

.page-wrapper .page-body-wrapper.sidebar-icon .sidebar-header {
  margin-top: 5px;
  text-align: center;
}

.page-wrapper .page-body-wrapper.sidebar-icon .sidebar-header > span {
  display: block !important;
  font-size: 11px;
  margin-top: 2px;
  color: #777777;
  letter-spacing: 0;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar {
  opacity: 1;
  z-index: 1;
  position: fixed;
  height: auto;
  display: table;
  width: 145px;
  left: 0;
  transition: all 0.5s ease;
  min-height: calc(100vh - 80px);
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-user {
  padding: 10px;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu {
  padding: 20px 0;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu > li {
  padding: 0 20px;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu > li.active a i, .page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu > li.active a span {
  color: #ab8ce4;
  transition: all 0.3s ease;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu > li > a:hover {
  padding-left: 0;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu > li > a:hover i, .page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu > li > a:hover span {
  color: #ab8ce4;
  transition: all 0.3s ease;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu .sidebar-header {
  margin-top: 5px;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu .sidebar-header > i {
  font-size: 25px;
  margin: 0;
  color: #717171;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu li:hover ul li .sidebar-submenu {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu li .icon-sidebar {
  font-size: 25px;
  margin: 0;
  color: #717171;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu li .sub-header-title {
  display: block;
  font-size: 11px;
  margin-top: 2px;
  color: #777777;
  letter-spacing: 0;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu li.active:hover > .sidebar-submenu {
  display: block;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu li.active > .sidebar-submenu {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-icon .page-sidebar .sidebar-menu li ul li:hover .sidebar-submenu {
  display: block;
  transform: rotateX(0deg);
  z-index: 1;
  background-color: #fff;
  color: #717171;
  font-size: 14px;
  opacity: 1;
  margin-top: -70px;
  padding: 10px 0;
  text-align: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
  position: absolute;
  width: 245px;
  margin-left: 225px;
}

.page-wrapper .page-body-wrapper.sidebar-icon .main-sidebar-menu {
  padding: 0 !important;
  text-align: center;
}

.page-wrapper .page-body-wrapper.sidebar-icon .sidebar-title {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-icon .sidebar-submenu {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-icon.page-body-wrapper .page-sidebar .sidebar-menu .sidebar-submenu > li > a {
  padding: 10px 20px;
}

.page-wrapper .page-body-wrapper.sidebar-icon .sidebar-header span {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-icon .sidebar-menu > li > a .fa-angle-right {
  display: none;
}

.sidebar-toggle-btn {
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
}

/* ========sidebar icon vertical menu end=======*/
/* ========sidebar hover vertical menu start=======*/
.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar {
  transform: translate(-180px);
  transition: 0.5s;
}

.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar .sidebar-user, .page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar .sidebar-header {
  text-align: right !important;
}

.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar:hover {
  transform: translate(0px);
}

.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar:hover .sidebar-user {
  text-align: center !important;
}

.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar:hover .sidebar-header {
  text-align: left !important;
}

.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar:hover .sidebar-menu li .icon-sidebar {
  margin-right: 5px;
}

.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar .sidebar-menu li .icon-sidebar {
  margin: 0;
}

.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar .sidebar-menu li.active > .sidebar-submenu {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar .sidebar-menu li:hover ul li .sidebar-submenu {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-hover .page-body {
  margin-left: 75px !important;
}

.page-wrapper .page-body-wrapper.sidebar-hover .page-body #customer-review .owl-stage-outer {
  width: 92%;
  margin: 0 auto;
}

.page-wrapper .page-body-wrapper.sidebar-hover .sidebar-title {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-hover .sidebar-submenu {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-hover .active {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-hover.page-body-wrapper .page-sidebar:hover .sidebar-menu .sidebar-header {
  text-align: left;
}

.page-wrapper .page-body-wrapper.sidebar-hover.page-body-wrapper .page-sidebar:hover .sidebar-menu li.active > .sidebar-submenu {
  display: inline-block;
}

.page-wrapper .page-body-wrapper.sidebar-hover.page-body-wrapper .page-sidebar:hover .sidebar-menu > li > a .fa-angle-right {
  display: inline-block;
}

.page-wrapper .page-body-wrapper.sidebar-hover.page-body-wrapper .page-sidebar:hover .sidebar-user {
  padding: 25px 10px;
}

.page-wrapper .page-body-wrapper.sidebar-hover.page-body-wrapper .page-sidebar:hover .sidebar-user h6 {
  display: inline-block;
}

.page-wrapper .page-body-wrapper.sidebar-hover.page-body-wrapper .page-sidebar:hover .sidebar-title {
  display: inline-block;
  width: 100%;
}

.page-wrapper .page-body-wrapper.sidebar-hover.page-body-wrapper .page-sidebar:hover .active {
  display: inline-block;
  width: 100%;
}

.page-wrapper .page-body-wrapper.sidebar-hover.page-body-wrapper .page-sidebar:hover .sidebar-header span {
  display: inline-block;
}

.page-wrapper .page-body-wrapper.sidebar-hover.page-body-wrapper .page-sidebar .sidebar-menu .sidebar-header {
  text-align: center;
}

.page-wrapper .page-body-wrapper.sidebar-hover.page-body-wrapper .page-sidebar .sidebar-user h6 {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-hover .sidebar-header span {
  display: none;
}

.page-wrapper .page-body-wrapper.sidebar-hover .sidebar-menu > li > a .fa-angle-right {
  display: none;
}

/* ========sidebar hover vertical menu ends=======*/
/*=======Mega menu css start=======*/
.mega-menu {
  perspective: inherit;
  position: static !important;
  padding-top: 5px;
  padding-left: 30px;
}

.mega-menu .default-according .card .btn-link {
  font-weight: 500;
  color: gray;
}

.mega-menu .default-according .card .card-body {
  font-size: 12px;
}

.mega-menu .onhover-show-div {
  width: 1500px;
}

.mega-menu .card-body {
  padding: 20px;
}

.mega-menu .title {
  color: #333333;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.mega-menu div > div a {
  margin-bottom: 0px;
  display: inline-block;
  color: #777777;
}

.mega-menu .custom-nav-img {
  position: static;
}

.mega-menu .custom-nav-img .desk {
  line-height: 25px;
  font-size: 14px;
  color: #777777;
}

.mega-menu .list-unstyled div a {
  padding: 8px 35px 8px 0;
  transition: all 0.3s ease;
}

.mega-menu .list-unstyled div a:hover {
  padding: 8px 35px 8px 10px;
  transition: all 0.3s ease;
}

.mega-menu .galleria div {
  margin-bottom: 5px;
}

.mega-menu .galleria div:hover {
  -webkit-transform: translateY(4px) scale(1.04);
  transform: translateY(4px) scale(1.04);
}

.mega-menu .galleria-list .galleria > div {
  width: 25%;
  font-size: 12px;
  float: left;
  color: #000;
  transition: ease-in 0.3s;
}

.mega-menu .galleria-list .galleria > div > a {
  display: block;
  margin: 5px 0 10px;
  padding: 0 0 !important;
}

.mega-menu .galleria-list .galleria > div img {
  border-radius: 3px;
  max-width: 100%;
}

.mega-menu .galleria-list .galleria > div .username {
  font-size: 12px;
  color: #333333;
  margin-bottom: 5px;
}

.mega-menu .galleria-list .galleria > div .username small {
  display: block;
  font-size: 11px;
  color: #777777;
  margin-top: 3px;
}

.mega-menu .drilldown {
  overflow: hidden;
  width: 100%;
  padding: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.mega-menu .drilldown a {
  width: 100%;
}

.mega-menu .drilldown a:hover {
  background-color: transparent;
  color: #ab8ce4;
}

.mega-menu .drilldown-sub {
  display: none;
}

.mega-menu .drilldown-back {
  font-weight: bold;
}

/*=======Mega menu css end=======*/
/*page chat quickview model start*/
.quickview-wrapper {
  position: fixed;
  right: -285px;
  top: 80px;
  width: 285px;
  background: #fff;
  bottom: 0;
  z-index: 10;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  border-left: 1px solid rgba(222, 227, 231, 0.56);
  transition: transform 400ms cubic-bezier(0.05, 0.74, 0.27, 0.99);
  height: calc(100% - 80px);
}

.quickview-wrapper.open {
  transform: translate3d(-100%, 0, 0);
}

.quickview-wrapper .friend-list-search {
  position: relative;
  background-color: #eeeeee;
  padding: 20px;
}

.quickview-wrapper .friend-list-search input {
  color: #717171;
  width: 100%;
  background-color: #fff;
  border: 1px solid #eeeeee;
  padding: 10px 15px;
  border-radius: 4px;
  letter-spacing: 1px;
}

.quickview-wrapper .friend-list-search .fa {
  position: absolute;
  right: 35px;
  top: 35px;
  font-size: 14px;
  color: #cccccc;
}

.quickview-wrapper .recent-activity {
  padding: 20px 40px;
  margin-left: 25px;
  position: relative;
}

.quickview-wrapper .recent-activity .user-first-letter:after {
  content: "";
  position: absolute;
  height: 100%;
  left: 0;
  top: 10px;
  float: left;
  border: 1px dashed #eeeeee;
}

.quickview-wrapper .recent-activity .user-first-letter span {
  position: absolute;
  left: -23px;
  width: 45px;
  text-align: center;
  color: #fff;
  height: 45px;
  border-radius: 50%;
  z-index: 1;
  font-size: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  top: 10px;
}

.quickview-wrapper .recent-activity .activity-content h6 {
  color: #444444;
}

.quickview-wrapper .recent-activity .activity-content p {
  font-size: 14px;
  color: #aaaaaa;
  margin: 0;
}

.quickview-wrapper .modal-header .modal-title {
  font-size: 15px;
  color: #444444;
  letter-spacing: 1px;
  font-weight: 500;
}

.quickview-wrapper .modal-header i {
  font-size: 16px;
  color: #717171;
}

.quickview-wrapper .quickview-box .quickview-box-toggle {
  position: absolute;
  width: 54px;
  height: 50px;
  top: 45%;
  left: -50px;
  text-align: center;
  line-height: 54px;
  cursor: pointer;
  padding: 0 !important;
}

.quickview-wrapper .quickview-box:before {
  position: absolute;
  content: '';
  width: 48px;
  height: 50px;
  background-color: #fff;
  top: 45%;
  left: -47px;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  border-left: 1px solid rgba(222, 227, 231, 0.56);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.quickview-wrapper .quickview-box:after {
  position: absolute;
  content: '';
  background-color: #fff;
  width: 5px;
  height: 50px;
  left: 0;
  top: 45%;
}

.quickview-wrapper .quickview-recent-activity .quickview-box-toggle {
  position: absolute;
  width: 54px;
  height: 50px;
  top: 52%;
  left: -50px;
  text-align: center;
  line-height: 54px;
  cursor: pointer;
  padding: 0 !important;
}

.quickview-wrapper .quickview-recent-activity:before {
  position: absolute;
  content: '';
  width: 48px;
  height: 50px;
  background-color: #fff;
  top: 52%;
  left: -47px;
  box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
  border-left: 1px solid rgba(222, 227, 231, 0.56);
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.quickview-wrapper .quickview-recent-activity:after {
  position: absolute;
  content: '';
  background-color: #fff;
  width: 5px;
  height: 50px;
  left: 0;
  top: 52%;
}

.quickview-wrapper .chat-box .friend-list {
  margin-top: 20px;
}

.chat-quickview {
  right: -400px;
  width: 400px;
}

.chat-quickview .chat-box .chat-left-aside .chat .chat-msg-box {
  height: 650px;
}

.chat-quickview .chat-box .chat-left-aside .chat .chat-msg-box .message {
  width: 100%;
}

.chat-quickview .chat-box .chat-left-aside .chat .chat-message {
  padding: 30px 0;
  margin: 30px 0;
  border-top: 1px solid #eeeeee;
}

/*page chat quickview model end*/
/*box layout start*/
.box-layout.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar {
  width: 75px;
  transform: translate(0px);
}

.box-layout.page-wrapper .page-body-wrapper.sidebar-hover .page-sidebar:hover {
  width: 255px;
}

.box-layout.page-wrapper .sidebar-close .page-sidebar.page-sidebar-open {
  display: none;
}

.box-layout.page-wrapper .page-body-wrapper.sidebar-close .page-sidebar {
  display: none;
}

.box-layout.page-wrapper .page-body-wrapper.sidebar-icon {
  overflow: hidden;
}

.box-layout.page-wrapper .page-body-wrapper.sidebar-icon.sidebar-close .page-sidebar-open {
  transform: translateX(-150px);
  left: unset;
}

.box-layout.page-wrapper .sidebar-icon .page-sidebar {
  position: absolute;
}

.box-layout.page-wrapper .sidebar-icon .page-sidebar > div {
  position: fixed;
  background-color: #fff;
  height: 100vh;
  width: 145px;
  -webkit-box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.13);
}

.box-layout.page-wrapper .page-main-header .main-header-right {
  margin: 0;
}

.box-layout.page-wrapper .page-main-header .vertical-menu-main .mega-menu {
  width: 1050px !important;
  max-width: 1050px !important;
  left: -80px !important;
}

.box-layout.page-wrapper .page-body-wrapper {
  width: 1280px;
  box-shadow: 1px 3px 25px rgba(0, 0, 0, 0.07);
  margin: 0 auto;
}

.box-layout.page-wrapper .page-body-wrapper .footer-fix {
  width: 1027px;
  margin: 0 auto;
  left: 256px;
  right: 0;
  padding-right: 0;
}

.box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-content {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 300px;
}

.box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-content > div {
  height: 450px;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0.75);
  background-blend-mode: overlay;
  width: 100%;
  padding: 30px;
  left: 15px;
}

.box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-content button {
  width: 40%;
  font-size: 14px;
  margin: 0 auto;
}

.box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons {
  margin-top: 20px;
  margin-bottom: 20px;
}

.box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons ul li {
  border: 1px solid #717171;
  width: 50px;
  height: 50px;
  padding: 8px;
}

.box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img {
  margin-top: 30px;
  margin-bottom: 30px;
}

.box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img img {
  width: 38%;
}

.box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .total-time h2 {
  font-size: 28px;
  color: #717171;
}

.box-layout.page-wrapper .page-body-wrapper canvas#myLineCharts {
  width: 100%;
}

.box-layout.page-wrapper .page-body-wrapper .chat-right-aside {
  flex: 0 0 60%;
  max-width: 60%;
  overflow: hidden;
}

.box-layout.page-wrapper .page-body-wrapper .caller-img {
  position: absolute;
  width: 100%;
  max-width: 100%;
  left: 15px;
}

.box-layout.page-wrapper .page-body-wrapper .caller-img img {
  opacity: 0.7;
}

.box-layout.page-wrapper .page-body-wrapper .browser-widget img {
  height: 65px;
}

.box-layout.page-wrapper .page-body-wrapper .weather-widget-two .bottom-whetherinfo .whether-content {
  top: 39px;
}

.box-layout.page-wrapper .page-body-wrapper .custom-card .card-header img {
  margin-top: -73px;
}

.box-layout.page-wrapper .page-body-wrapper .custom-card .card-profile img {
  height: 130px;
  top: -17px;
}

.box-layout.page-wrapper .page-body-wrapper .height-scroll {
  max-height: 444px;
}

.box-layout.page-wrapper .page-body-wrapper .select2 {
  width: 901.781px;
}

.box-layout.page-wrapper .page-body-wrapper.sidebar-hover .page-body #customer-review .owl-stage-outer {
  width: 85%;
}

.box-layout.page-wrapper .page-main-header {
  max-width: 1280px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.box-layout.page-wrapper .page-builder .ge-canvas.ge-layout-desktop {
  margin-top: 50px;
}

.box-layout.page-wrapper .page-builder .ge-addRowGroup {
  margin-bottom: 10px;
}

.box-layout.page-wrapper .pricing-wrapper-card {
  padding: 50px 20px;
}

.box-layout.page-wrapper .card .blog-box.blog-grid.set-min-height {
  min-height: 400px;
}

.box-layout.page-wrapper .card .card-body .top-sale-chart canvas#myLineCharts {
  min-height: 410px;
}

.box-layout.page-wrapper .flot-chart-placeholder#donut-color-chart-morris-daily {
  min-height: 430px;
}

.box-layout.page-wrapper .flot-chart-placeholder#donut-color-chart-morris {
  min-height: 430px;
}

.box-layout.page-wrapper .set-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.box-layout.page-wrapper .set-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.box-layout.page-wrapper .set-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.box-layout.page-wrapper .set-col-7 {
  flex: 0 0 60%;
  max-width: 60%;
}

.box-layout.page-wrapper .set-col-5 {
  flex: 0 0 40%;
  max-width: 40%;
}

.box-layout.page-wrapper .chat-box .chat-right-aside {
  flex: 0 0 100%;
  max-width: calc(100% - 15px);
  overflow: hidden;
}

.box-layout.page-wrapper .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
  font-size: 19px;
}

.box-layout.page-wrapper .chat-box .toogle-bar {
  display: inline-block;
  margin-right: 0 !important;
}

.box-layout.page-wrapper .chat-menu {
  right: 0;
  border-top: 1px solid #ddd;
  opacity: 0;
  transform: translateY(-30px);
  visibility: hidden;
  top: 81px;
  position: absolute;
  z-index: 9;
  background-color: #fff;
  transition: all linear 0.3s;
}

.box-layout.page-wrapper .chat-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: all linear 0.3s;
  padding-bottom: 25px;
}

.box-layout.page-wrapper .ct-10.total-chart .ct-chart-bar .ct-series .ct-bar {
  stroke-width: 23px !important;
}

.box-layout.page-wrapper .email-wrap .email-body .email-compose .cke_contents.cke_reset {
  max-height: 197px;
}

.box-layout.page-wrapper .email-wrap .email-right-aside .email-body .inbox {
  height: 734px;
}

.box-layout.page-wrapper .email-wrap .email-content .email-top .user-emailid:after {
  right: -10px;
  top: -21px;
}

.box-layout.page-wrapper .todo .notification-popup {
  right: 320px;
}

.box-layout.page-wrapper .touchspin {
  padding: 0 10px;
}

.box-layout.page-wrapper .vertical-menu-main {
  width: 1280px;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.box-layout.page-wrapper .vertical-menu-main .mega-menu {
  width: 1050px !important;
  max-width: 1050px !important;
  left: -320px !important;
}

.box-layout.page-wrapper .comingsoon video {
  min-width: 67%;
  width: 67%;
}

.box-layout.page-wrapper .authentication-main .auth-innerleft {
  width: 22.333333%;
}

.box-layout.page-wrapper .auth-bg-effect .second-effect {
  left: 55%;
}

.box-layout.page-wrapper .auth-bg-video video {
  min-width: 67%;
  width: 67%;
}

/*box layout Ends*/
.sidebar-widget {
  padding-top: 20px;
  border-top: 1px solid #eeeeee;
  padding-bottom: 20px;
}

.sidebar-widget .sidebar-widget-top {
  margin-bottom: -50px;
}

.sidebar-widget .sidebar-widget-top i {
  height: 60px;
  width: 60px;
  background-color: #fff;
  display: inline-block;
  font-size: 24px;
  border-radius: 100%;
  line-height: 2.3;
}

.sidebar-widget .sidebar-widget-bottom {
  background-color: #eeeeee;
  text-align: center;
}

.sidebar-widget .sidebar-widget-bottom p {
  line-height: 2.3;
  padding-top: 26px;
  font-size: 15px;
  margin-bottom: 0;
}

/**=====================
    05. Header CSS Ends
==========================**/
/**=====================
    06. Button CSS start
==========================**/
.btn-air-primary {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}

.btn-air-primary:hover, .btn-air-primary:active, .btn-air-primary:not([disabled]):not(.disabled):active {
  background-color: #8d63da;
  border-color: #8d63da;
}

.btn-air-secondary {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}

.btn-air-secondary:hover, .btn-air-secondary:active, .btn-air-secondary:not([disabled]):not(.disabled):active {
  background-color: #1e9faf;
  border-color: #1e9faf;
}

.btn-air-success {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}

.btn-air-success:hover, .btn-air-success:active, .btn-air-success:not([disabled]):not(.disabled):active {
  background-color: #008f6c;
  border-color: #008f6c;
}

.btn-air-danger {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}

.btn-air-danger:hover, .btn-air-danger:active, .btn-air-danger:not([disabled]):not(.disabled):active {
  background-color: #ff2046;
  border-color: #ff2046;
}

.btn-air-info {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}

.btn-air-info:hover, .btn-air-info:active, .btn-air-info:not([disabled]):not(.disabled):active {
  background-color: #0d7eff;
  border-color: #0d7eff;
}

.btn-air-light {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}

.btn-air-light:hover, .btn-air-light:active, .btn-air-light:not([disabled]):not(.disabled):active {
  background-color: #d5d4d4;
  border-color: #d5d4d4;
}

.btn-air-dark {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}

.btn-air-dark:hover, .btn-air-dark:active, .btn-air-dark:not([disabled]):not(.disabled):active {
  background-color: #161a23;
  border-color: #161a23;
}

.btn-air-warning {
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}

.btn-air-warning:hover, .btn-air-warning:active, .btn-air-warning:not([disabled]):not(.disabled):active {
  background-color: #c0ab00;
  border-color: #c0ab00;
}

.input-air-primary {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-primary:focus {
  border-color: #ab8ce4;
}

.input-air-secondary {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-secondary:focus {
  border-color: #26c6da;
}

.input-air-success {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-success:focus {
  border-color: #00c292;
}

.input-air-danger {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-danger:focus {
  border-color: #FF5370;
}

.input-air-info {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-info:focus {
  border-color: #4099ff;
}

.input-air-light {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-light:focus {
  border-color: #eeeeee;
}

.input-air-dark {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-dark:focus {
  border-color: #2a3142;
}

.input-air-warning {
  box-shadow: 0 3px 5px 1px rgba(88, 103, 221, 0.15) !important;
}

.input-air-warning:focus {
  border-color: #f3d800;
}

.form-bg-primary {
  background: #ab8ce4;
  border-color: #ab8ce4;
  color: #fff;
}

.form-bg-primary:focus {
  border-color: #ab8ce4;
  background: #ab8ce4;
  color: #fff;
}

.form-bg-primary:focus:active {
  border-color: #ab8ce4;
  background: #ab8ce4;
  color: #fff;
}

.form-bg-secondary {
  background: #26c6da;
  border-color: #26c6da;
  color: #fff;
}

.form-bg-secondary:focus {
  border-color: #26c6da;
  background: #26c6da;
  color: #fff;
}

.form-bg-secondary:focus:active {
  border-color: #26c6da;
  background: #26c6da;
  color: #fff;
}

.form-bg-success {
  background: #00c292;
  border-color: #00c292;
  color: #fff;
}

.form-bg-success:focus {
  border-color: #00c292;
  background: #00c292;
  color: #fff;
}

.form-bg-success:focus:active {
  border-color: #00c292;
  background: #00c292;
  color: #fff;
}

.form-bg-danger {
  background: #FF5370;
  border-color: #FF5370;
  color: #fff;
}

.form-bg-danger:focus {
  border-color: #FF5370;
  background: #FF5370;
  color: #fff;
}

.form-bg-danger:focus:active {
  border-color: #FF5370;
  background: #FF5370;
  color: #fff;
}

.form-bg-info {
  background: #4099ff;
  border-color: #4099ff;
  color: #fff;
}

.form-bg-info:focus {
  border-color: #4099ff;
  background: #4099ff;
  color: #fff;
}

.form-bg-info:focus:active {
  border-color: #4099ff;
  background: #4099ff;
  color: #fff;
}

.form-bg-light {
  background: #eeeeee;
  border-color: #eeeeee;
  color: #2a3142;
}

.form-bg-light:focus {
  border-color: #eeeeee;
  background: #eeeeee;
  color: #2a3142;
}

.form-bg-light:focus:active {
  border-color: #eeeeee;
  background: #eeeeee;
  color: #2a3142;
}

.form-bg-dark {
  background: #2a3142;
  border-color: #2a3142;
  color: #fff;
}

.form-bg-dark:focus {
  border-color: #2a3142;
  background: #2a3142;
  color: #fff;
}

.form-bg-dark:focus:active {
  border-color: #2a3142;
  background: #2a3142;
  color: #fff;
}

.form-bg-warning {
  background: #f3d800;
  border-color: #f3d800;
  color: #fff;
}

.form-bg-warning:focus {
  border-color: #f3d800;
  background: #f3d800;
  color: #fff;
}

.form-bg-warning:focus:active {
  border-color: #f3d800;
  background: #f3d800;
  color: #fff;
}

.btn-primary-gradien {
  background-image: linear-gradient(to right, #c3adec 0%, #936bdc 51%, #c3adec 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}

.btn-primary-gradien:focus, .btn-primary-gradien:active, .btn-primary-gradien.active {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #c3adec, 0%, #936bdc, 100%, #fff) !important;
}

.btn-secondary-gradien {
  background-image: linear-gradient(to right, #49cfe0 0%, #1fa7b8 51%, #49cfe0 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}

.btn-secondary-gradien:hover, .btn-secondary-gradien:focus, .btn-secondary-gradien:active, .btn-secondary-gradien.active {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #49cfe0, 0%, #1fa7b8, 100%, #fff) !important;
}

.btn-success-gradien {
  background-image: linear-gradient(to right, #00ebb1 0%, #009973 51%, #00ebb1 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}

.btn-success-gradien:hover, .btn-success-gradien:focus, .btn-success-gradien:active, .btn-success-gradien.active {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #00ebb1, 0%, #009973, 100%, #fff) !important;
}

.btn-danger-gradien {
  background-image: linear-gradient(to right, #ff7c92 0%, #ff2a4e 51%, #ff7c92 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}

.btn-danger-gradien:hover, .btn-danger-gradien:focus, .btn-danger-gradien:active, .btn-danger-gradien.active {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #ff7c92, 0%, #ff2a4e, 100%, #fff) !important;
}

.btn-warning-gradien {
  background-image: linear-gradient(to right, #ffe61d 0%, #cab400 51%, #ffe61d 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}

.btn-warning-gradien:hover, .btn-warning-gradien:focus, .btn-warning-gradien:active, .btn-warning-gradien.active {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #ffe61d, 0%, #cab400, 100%, #fff) !important;
}

.btn-info-gradien {
  background-image: linear-gradient(to right, #69afff 0%, #1783ff 51%, #69afff 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}

.btn-info-gradien:hover, .btn-info-gradien:focus, .btn-info-gradien:active, .btn-info-gradien.active {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #69afff, 0%, #1783ff, 100%, #fff) !important;
}

.btn-light-gradien {
  background-image: linear-gradient(to right, white 0%, #dadada 51%, white 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}

.btn-light-gradien:hover, .btn-light-gradien:focus, .btn-light-gradien:active, .btn-light-gradien.active {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, white, 0%, #dadada, 100%, #fff) !important;
}

.btn-dark-gradien {
  background-image: linear-gradient(to right, #3a445b 0%, #1a1e29 51%, #3a445b 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  transition: all 0.3s ease;
}

.btn-dark-gradien:hover, .btn-dark-gradien:focus, .btn-dark-gradien:active, .btn-dark-gradien.active {
  transition: 1.5s;
  background-position: right center;
  background-image: linear-gradient(to right, #3a445b, 0%, #1a1e29, 100%, #fff) !important;
}

[class*='-gradien']:hover {
  background-size: 50% 100%;
  transition: all 0.3s ease;
}

.large-btn {
  margin-bottom: -20px;
}

.large-btn .btn {
  margin-right: 5px;
  margin-bottom: 15px;
}

.btn-square {
  border-radius: 0px;
}

.btn-pill {
  border-radius: 60px;
}

.btn-group-pill .btn:first-child {
  border-radius: 60px 0 0 60px;
}

.btn-group-pill .btn:last-child {
  border-radius: 0 60px 60px 0;
}

.btn-group-square .btn:first-child {
  border-radius: 0px 0 0 0px;
}

.btn-group-square .btn:last-child {
  border-radius: 0 0px 0px 0;
}

.btn-showcase {
  margin-bottom: -10px;
}

.btn-showcase .btn {
  margin-bottom: 10px;
  margin-right: 18px;
}

/**=====================
     07. Color CSS Start
==========================**/
.color-box {
  margin-bottom: -10px;
}

.primary-color ul li:nth-child(13) {
  background-color: #b092e5;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(13) span:before {
  content: "#b092e5";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:nth-child(12) {
  background-color: #b498e7;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(12) span:before {
  content: "#b498e7";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:nth-child(11) {
  background-color: #b99fe8;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(11) span:before {
  content: "#b99fe8";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:nth-child(10) {
  background-color: #bda5ea;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(10) span:before {
  content: "#bda5ea";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:nth-child(9) {
  background-color: #c2abeb;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(9) span:before {
  content: "#c2abeb";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:nth-child(8) {
  background-color: #c6b1ed;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(8) span:before {
  content: "#c6b1ed";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:nth-child(7) {
  background-color: #cbb7ee;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(7) span:before {
  content: "#cbb7ee";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:nth-child(6) {
  background-color: #cfbef0;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(6) span:before {
  content: "#cfbef0";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:nth-child(5) {
  background-color: #d4c4f1;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(5) span:before {
  content: "#d4c4f1";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:nth-child(4) {
  background-color: #d8caf3;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(4) span:before {
  content: "#d8caf3";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:nth-child(3) {
  background-color: #ddd0f4;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(3) span:before {
  content: "#ddd0f4";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:nth-child(2) {
  background-color: #e1d6f5;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(2) span:before {
  content: "#e1d6f5";
  display: block;
  color: #fff;
  text-align: center;
}

.primary-color ul li:nth-child(1) {
  background-color: #e6ddf7;
  padding: 10px 15px;
}

.primary-color ul li:nth-child(1) span:before {
  content: "#e6ddf7";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(13) {
  background-color: #33c9dc;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(13) span:before {
  content: "#33c9dc";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(12) {
  background-color: #40cdde;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(12) span:before {
  content: "#40cdde";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(11) {
  background-color: #4dd0e1;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(11) span:before {
  content: "#4dd0e1";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(10) {
  background-color: #5ad4e3;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(10) span:before {
  content: "#5ad4e3";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(9) {
  background-color: #67d7e5;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(9) span:before {
  content: "#67d7e5";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(8) {
  background-color: #74dbe7;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(8) span:before {
  content: "#74dbe7";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(7) {
  background-color: #81deea;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(7) span:before {
  content: "#81deea";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(6) {
  background-color: #8fe1ec;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(6) span:before {
  content: "#8fe1ec";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(5) {
  background-color: #9ce5ee;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(5) span:before {
  content: "#9ce5ee";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(4) {
  background-color: #a9e8f0;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(4) span:before {
  content: "#a9e8f0";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(3) {
  background-color: #b6ecf3;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(3) span:before {
  content: "#b6ecf3";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(2) {
  background-color: #c3eff5;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(2) span:before {
  content: "#c3eff5";
  display: block;
  color: #fff;
  text-align: center;
}

.secondary-color ul li:nth-child(1) {
  background-color: #d0f3f7;
  padding: 10px 15px;
}

.secondary-color ul li:nth-child(1) span:before {
  content: "#d0f3f7";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(13) {
  background-color: #00c795;
  padding: 10px 15px;
}

.success-color ul li:nth-child(13) span:before {
  content: "#00c795";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(12) {
  background-color: #00cb99;
  padding: 10px 15px;
}

.success-color ul li:nth-child(12) span:before {
  content: "#00cb99";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(11) {
  background-color: #00d09c;
  padding: 10px 15px;
}

.success-color ul li:nth-child(11) span:before {
  content: "#00d09c";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(10) {
  background-color: #00d4a0;
  padding: 10px 15px;
}

.success-color ul li:nth-child(10) span:before {
  content: "#00d4a0";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(9) {
  background-color: #00d9a3;
  padding: 10px 15px;
}

.success-color ul li:nth-child(9) span:before {
  content: "#00d9a3";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(8) {
  background-color: #00dea7;
  padding: 10px 15px;
}

.success-color ul li:nth-child(8) span:before {
  content: "#00dea7";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(7) {
  background-color: #00e2aa;
  padding: 10px 15px;
}

.success-color ul li:nth-child(7) span:before {
  content: "#00e2aa";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(6) {
  background-color: #00e7ae;
  padding: 10px 15px;
}

.success-color ul li:nth-child(6) span:before {
  content: "#00e7ae";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(5) {
  background-color: #00ebb1;
  padding: 10px 15px;
}

.success-color ul li:nth-child(5) span:before {
  content: "#00ebb1";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(4) {
  background-color: #00f0b5;
  padding: 10px 15px;
}

.success-color ul li:nth-child(4) span:before {
  content: "#00f0b5";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(3) {
  background-color: #00f4b8;
  padding: 10px 15px;
}

.success-color ul li:nth-child(3) span:before {
  content: "#00f4b8";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(2) {
  background-color: #00f9bb;
  padding: 10px 15px;
}

.success-color ul li:nth-child(2) span:before {
  content: "#00f9bb";
  display: block;
  color: #fff;
  text-align: center;
}

.success-color ul li:nth-child(1) {
  background-color: #00febf;
  padding: 10px 15px;
}

.success-color ul li:nth-child(1) span:before {
  content: "#00febf";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(13) {
  background-color: #4da0ff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(13) span:before {
  content: "#4da0ff";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(12) {
  background-color: #5aa7ff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(12) span:before {
  content: "#5aa7ff";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(11) {
  background-color: #66adff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(11) span:before {
  content: "#66adff";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(10) {
  background-color: #73b4ff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(10) span:before {
  content: "#73b4ff";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(9) {
  background-color: #80bbff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(9) span:before {
  content: "#80bbff";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(8) {
  background-color: #8dc2ff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(8) span:before {
  content: "#8dc2ff";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(7) {
  background-color: #99c9ff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(7) span:before {
  content: "#99c9ff";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(6) {
  background-color: #a6cfff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(6) span:before {
  content: "#a6cfff";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(5) {
  background-color: #b3d6ff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(5) span:before {
  content: "#b3d6ff";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(4) {
  background-color: #c0ddff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(4) span:before {
  content: "#c0ddff";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(3) {
  background-color: #cce4ff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(3) span:before {
  content: "#cce4ff";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(2) {
  background-color: #d9ebff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(2) span:before {
  content: "#d9ebff";
  display: block;
  color: #fff;
  text-align: center;
}

.info-color ul li:nth-child(1) {
  background-color: #e6f2ff;
  padding: 10px 15px;
}

.info-color ul li:nth-child(1) span:before {
  content: "#e6f2ff";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(13) {
  background-color: #ffe303;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(13) span:before {
  content: "#ffe303";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(12) {
  background-color: #ffe513;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(12) span:before {
  content: "#ffe513";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(11) {
  background-color: #ffe622;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(11) span:before {
  content: "#ffe622";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(10) {
  background-color: #ffe831;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(10) span:before {
  content: "#ffe831";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(9) {
  background-color: #ffea41;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(9) span:before {
  content: "#ffea41";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(8) {
  background-color: #ffec50;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(8) span:before {
  content: "#ffec50";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(7) {
  background-color: #ffed5f;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(7) span:before {
  content: "#ffed5f";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(6) {
  background-color: #ffef6e;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(6) span:before {
  content: "#ffef6e";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(5) {
  background-color: #fff17e;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(5) span:before {
  content: "#fff17e";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(4) {
  background-color: #fff28d;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(4) span:before {
  content: "#fff28d";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(3) {
  background-color: #fff49c;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(3) span:before {
  content: "#fff49c";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(2) {
  background-color: #fff6ac;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(2) span:before {
  content: "#fff6ac";
  display: block;
  color: #fff;
  text-align: center;
}

.yellow-color ul li:nth-child(1) {
  background-color: #fff7bb;
  padding: 10px 15px;
}

.yellow-color ul li:nth-child(1) span:before {
  content: "#fff7bb";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(13) {
  background-color: #ff5d78;
  padding: 10px 15px;
}

.red-color ul li:nth-child(13) span:before {
  content: "#ff5d78";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(12) {
  background-color: #ff6781;
  padding: 10px 15px;
}

.red-color ul li:nth-child(12) span:before {
  content: "#ff6781";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(11) {
  background-color: #ff7289;
  padding: 10px 15px;
}

.red-color ul li:nth-child(11) span:before {
  content: "#ff7289";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(10) {
  background-color: #ff7c92;
  padding: 10px 15px;
}

.red-color ul li:nth-child(10) span:before {
  content: "#ff7c92";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(9) {
  background-color: #ff869a;
  padding: 10px 15px;
}

.red-color ul li:nth-child(9) span:before {
  content: "#ff869a";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(8) {
  background-color: #ff90a3;
  padding: 10px 15px;
}

.red-color ul li:nth-child(8) span:before {
  content: "#ff90a3";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(7) {
  background-color: #ff9aab;
  padding: 10px 15px;
}

.red-color ul li:nth-child(7) span:before {
  content: "#ff9aab";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(6) {
  background-color: #ffa5b4;
  padding: 10px 15px;
}

.red-color ul li:nth-child(6) span:before {
  content: "#ffa5b4";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(5) {
  background-color: #ffafbc;
  padding: 10px 15px;
}

.red-color ul li:nth-child(5) span:before {
  content: "#ffafbc";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(4) {
  background-color: #ffb9c5;
  padding: 10px 15px;
}

.red-color ul li:nth-child(4) span:before {
  content: "#ffb9c5";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(3) {
  background-color: #ffc3cd;
  padding: 10px 15px;
}

.red-color ul li:nth-child(3) span:before {
  content: "#ffc3cd";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(2) {
  background-color: #ffcdd6;
  padding: 10px 15px;
}

.red-color ul li:nth-child(2) span:before {
  content: "#ffcdd6";
  display: block;
  color: #fff;
  text-align: center;
}

.red-color ul li:nth-child(1) {
  background-color: #ffd8de;
  padding: 10px 15px;
}

.red-color ul li:nth-child(1) span:before {
  content: "#ffd8de";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(13) {
  background-color: #eb2067;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(13) span:before {
  content: "#eb2067";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(12) {
  background-color: #ec2e70;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(12) span:before {
  content: "#ec2e70";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(11) {
  background-color: #ed3c7a;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(11) span:before {
  content: "#ed3c7a";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(10) {
  background-color: #ee4a83;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(10) span:before {
  content: "#ee4a83";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(9) {
  background-color: #f0588d;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(9) span:before {
  content: "#f0588d";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(8) {
  background-color: #f16696;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(8) span:before {
  content: "#f16696";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(7) {
  background-color: #f274a0;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(7) span:before {
  content: "#f274a0";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(6) {
  background-color: #f482aa;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(6) span:before {
  content: "#f482aa";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(5) {
  background-color: #f590b3;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(5) span:before {
  content: "#f590b3";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(4) {
  background-color: #f69ebd;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(4) span:before {
  content: "#f69ebd";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(3) {
  background-color: #f7acc6;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(3) span:before {
  content: "#f7acc6";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(2) {
  background-color: #f9bad0;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(2) span:before {
  content: "#f9bad0";
  display: block;
  color: #fff;
  text-align: center;
}

.pink-color ul li:nth-child(1) {
  background-color: #fac8d9;
  padding: 10px 15px;
}

.pink-color ul li:nth-child(1) span:before {
  content: "#fac8d9";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(13) {
  background-color: #797979;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(13) span:before {
  content: "#797979";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(12) {
  background-color: gray;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(12) span:before {
  content: "gray";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(11) {
  background-color: #888888;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(11) span:before {
  content: "#888888";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(10) {
  background-color: #909090;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(10) span:before {
  content: "#909090";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(9) {
  background-color: #979797;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(9) span:before {
  content: "#979797";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(8) {
  background-color: #9f9f9f;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(8) span:before {
  content: "#9f9f9f";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(7) {
  background-color: #a7a7a7;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(7) span:before {
  content: "#a7a7a7";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(6) {
  background-color: #aeaeae;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(6) span:before {
  content: "#aeaeae";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(5) {
  background-color: #b6b6b6;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(5) span:before {
  content: "#b6b6b6";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(4) {
  background-color: #bebdbd;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(4) span:before {
  content: "#bebdbd";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(3) {
  background-color: #c5c5c5;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(3) span:before {
  content: "#c5c5c5";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(2) {
  background-color: #cdcdcd;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(2) span:before {
  content: "#cdcdcd";
  display: block;
  color: #fff;
  text-align: center;
}

.gray-color ul li:nth-child(1) {
  background-color: #d4d4d4;
  padding: 10px 15px;
}

.gray-color ul li:nth-child(1) span:before {
  content: "#d4d4d4";
  display: block;
  color: #fff;
  text-align: center;
}

/**=====================
     07. Color CSS Ends
==========================**/
/**=====================
    25. Authentication CSS Start
==========================**/
.authentication-main .auth-innerleft, .authentication-main .auth-innerright {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 100px;
}

.authentication-main {
  background: #fafafa;
}

.authentication-main .auth-innerleft {
  background: #fff;
  box-shadow: 1px 1px 1px 0 #eeeeee;
  background-repeat: no-repeat;
  position: fixed;
  width: 33.3333333333%;
  height: 100%;
}

.authentication-main .auth-innerleft hr {
  width: 250px;
}

.authentication-main .auth-innerleft .logo-login {
  width: 35%;
}

.authentication-main .auth-innerleft .social-media li {
  color: #717171;
  font-size: 18px;
}

.authentication-main .auth-innerleft .social-media li:hover {
  color: #26c6da;
  cursor: pointer;
}

.authentication-main .auth-innerright {
  background: #fafafa;
  justify-content: left;
}

.authentication-box {
  width: 645px;
  z-index: 2;
}

.authentication-box h3 {
  color: #2a3142;
  font-weight: 600;
  letter-spacing: 1px;
}

.authentication-box h4 {
  color: #333333;
  font-weight: 600;
  letter-spacing: 1px;
}

.authentication-box h6 {
  color: #777777;
  letter-spacing: 1px;
  font-size: 14px;
}

.auth-bg {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px 100px;
}

.auth-bg-video {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.31);
  padding: 50px 0;
}

.auth-bg-video video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  transition: 1s opacity;
}

.auth-bg-effect {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.auth-bg-effect .first-effect {
  width: 250px;
  height: 250px;
  background: white no-repeat;
  background-size: cover;
  animation: rotate-effect 40s infinite linear;
  position: absolute;
  bottom: 130px;
}

.auth-bg-effect .second-effect {
  width: 220px;
  height: 220px;
  background: white no-repeat;
  background-size: cover;
  animation: rotate-effect 40s infinite linear;
  position: absolute;
  bottom: 65px;
  left: 61%;
}

.auth-bg-effect .third-effect {
  width: 200px;
  height: 200px;
  background: white no-repeat;
  background-size: cover;
  animation: rotate-effect 40s infinite linear;
  position: absolute;
  bottom: 45px;
  left: 38%;
}

.auth-bg-effect .fourth-effect {
  width: 200px;
  height: 200px;
  background: white no-repeat;
  background-size: cover;
  animation: rotate-effect 40s infinite linear;
  position: absolute;
  bottom: 407px;
  left: 1%;
}

.reset-password-box {
  width: 645px;
  z-index: 2;
}

.reset-password-box .card {
  padding: 30px;
}

.reset-password-box .theme-form .form-group label {
  font-size: 12px;
  color: #777777;
}

.reset-password-box .theme-form .form-group .form-control {
  font-size: 18px;
}

.reset-password-box .theme-form .form-group .btn {
  font-size: 18px;
  padding: 6px 24px;
}

.reset-password-box .theme-form .opt-box {
  background: #f7f7f7;
}

.reset-password-box .theme-form .opt-box .opt-text {
  font-size: 36px;
  padding: 0;
}

.reset-password-box .reset-password-link {
  color: #777777;
}

.reset-password-box .reset-password-box h6 {
  letter-spacing: 1px;
  font-size: 16px;
}

@keyframes rotate-effect {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/**=====================
    25. Authentication CSS Ends
==========================**/
/**=====================
    26. Form CSS Start
==========================**/
.ngx-form .f1-buttons .btn-previous {
  margin-right: 5px;
}

.ngx-form .alert {
  color: red;
  padding: 0;
  font-size: 12px;
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li a .label {
  font-size: 13px !important;
  text-transform: capitalize !important;
  font-weight: 400 !important;
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li.current a .label {
  color: #ab8ce4;
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li.navigable a .label {
  color: #ab8ce4;
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li.navigable:after {
  background-color: #ab8ce4 !important;
}

aw-wizard-navigation-bar.horizontal ul.steps-indicator li.navigable:before {
  background-color: #ab8ce4 !important;
}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator {
  padding: 60px 0 10px;
  font-size: 18px;
}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.done .step-indicator {
  background-color: #fff !important;
  color: #ab8ce4 !important;
  border: 1px solid #ab8ce4;
}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.current .step-indicator {
  background-color: #ab8ce4 !important;
  color: #fafafa !important;
}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.current:first-child:after {
  background-color: #ab8ce4 !important;
}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li:first-child:after {
  background-color: #e6e6e6;
  content: "";
  position: absolute;
  height: 1px;
  width: 45%;
  top: -25px;
  right: calc(50% + 50px / 2);
}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li:last-child:after {
  background-color: #e6e6e6;
  content: "";
  position: absolute;
  height: 1px;
  width: 45%;
  top: -25px;
  left: calc(50% + 50px / 2);
}

aw-wizard-navigation-bar.horizontal.large-filled-symbols ul.steps-indicator li.editing .step-indicator {
  background-color: #ab8ce4 !important;
  color: #fafafa !important;
}

.height-35 {
  height: 35px !important;
}

.theme-form input[type=file] {
  height: auto;
}

.theme-form .form-control :focus {
  box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
}

.theme-form.sm-form .form-group {
  margin-bottom: 14px;
}

.theme-form.form {
  margin-bottom: -20px;
}

.theme-form .form-group {
  margin-bottom: 20px;
}

.theme-form .form-group textarea {
  border-color: #ddd;
}

.theme-form .form-group textarea::-webkit-input-placeholder {
  color: #aaaaaa;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}

.theme-form .form-group input[type=text], .theme-form .form-group input[type=email], .theme-form .form-group input[type=search],
.theme-form .form-group input[type=password], .theme-form .form-group input[type=number], .theme-form .form-group input[type=tel], .theme-form .form-group input[type=date], .theme-form .form-group input[type=datetime-local], .theme-form .form-group input[type=time], .theme-form .form-group input[type=datetime-local], .theme-form .form-group input[type=month], .theme-form .form-group input[type=week],
.theme-form .form-group input[type=url], .theme-form .form-group input[type=file], .theme-form .form-group select {
  border-color: #ddd;
  background-color: #fff;
  font-size: 14px;
  color: #717171;
  font-family: Open Sans;
}

.theme-form .form-group input[type=text]::-webkit-input-placeholder, .theme-form .form-group input[type=email]::-webkit-input-placeholder, .theme-form .form-group input[type=search]::-webkit-input-placeholder,
.theme-form .form-group input[type=password]::-webkit-input-placeholder, .theme-form .form-group input[type=number]::-webkit-input-placeholder, .theme-form .form-group input[type=tel]::-webkit-input-placeholder, .theme-form .form-group input[type=date]::-webkit-input-placeholder, .theme-form .form-group input[type=datetime-local]::-webkit-input-placeholder, .theme-form .form-group input[type=time]::-webkit-input-placeholder, .theme-form .form-group input[type=datetime-local]::-webkit-input-placeholder, .theme-form .form-group input[type=month]::-webkit-input-placeholder, .theme-form .form-group input[type=week]::-webkit-input-placeholder,
.theme-form .form-group input[type=url]::-webkit-input-placeholder, .theme-form .form-group input[type=file]::-webkit-input-placeholder, .theme-form .form-group select::-webkit-input-placeholder {
  color: #717171;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
}

.theme-form .form-group select.form-control:not([size]):not([multiple]) {
  border-color: #ddd;
  font-size: 14px;
}

.theme-form .checkbox label {
  padding-left: 10px;
}

.theme-form .form-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #ddd;
}

.theme-form .form-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  content: "";
  color: #444444;
  background: #fff;
}

.theme-form .btn-fb {
  background-color: #50598e;
  color: #fff;
  width: 100%;
}

.theme-form .btn-twitter {
  background-color: #6fa2d8;
  color: #fff;
  width: 100%;
}

.theme-form .btn-google {
  background-color: #c64e40;
  color: #fff;
  width: 100%;
}

.form-inline .form-group {
  margin-right: 15px;
}

.form-inline .form-group .col-form-label {
  margin-right: 5px;
}

.search-form input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  background-repeat: no-repeat;
  background-position: 95%;
}

.form-label-align-right label {
  text-align: right;
  padding-top: 17px;
  font-weight: 500;
}

.custom-file-label {
  line-height: 1.8;
}

.custom-file-label::after {
  line-height: 1.8;
}

/**=====================
    26. Form CSS Ends
==========================**/
/**=====================
     32. Page CSS Start
==========================**/
.page-link {
  color: #ab8ce4;
}

.page-item.active.active .page-link {
  background-color: #ab8ce4;
  border-color: #ab8ce4;
}

.pagination-space p {
  margin-bottom: 5px;
}

.pagination-space .pagination-top {
  margin-top: 15px;
}

/*test Nav bar color */
.pagination-theme .page-item .page-link {
  color: #ab8ce4;
}

.pagination-theme .page-item.active .page-link {
  color: #fff;
  background-color: #ab8ce4;
  border-color: #ab8ce4;
}

.pagination-primary .page-item .page-link {
  color: #ab8ce4;
}

.pagination-primary .page-item.active .page-link {
  color: #fff;
  background-color: #ab8ce4;
  border-color: #ab8ce4;
}

.pagination-secondary .page-item .page-link {
  color: #26c6da;
}

.pagination-secondary .page-item.active .page-link {
  color: #fff;
  background-color: #26c6da;
  border-color: #26c6da;
}

.pagination-success .page-item .page-link {
  color: #00c292;
}

.pagination-success .page-item.active .page-link {
  color: #fff;
  background-color: #00c292;
  border-color: #00c292;
}

.pagination-danger .page-item .page-link {
  color: #FF5370;
}

.pagination-danger .page-item.active .page-link {
  color: #fff;
  background-color: #FF5370;
  border-color: #FF5370;
}

.pagination-info .page-item .page-link {
  color: #4099ff;
}

.pagination-info .page-item.active .page-link {
  color: #fff;
  background-color: #4099ff;
  border-color: #4099ff;
}

.pagination-dark .page-item .page-link {
  color: #2a3142;
}

.pagination-dark .page-item.active .page-link {
  color: #fff;
  background-color: #2a3142;
  border-color: #2a3142;
}

.pagination-warning .page-item .page-link {
  color: #f3d800;
}

.pagination-warning .page-item.active .page-link {
  color: #fff;
  background-color: #f3d800;
  border-color: #f3d800;
}

/**=====================
     32. Page CSS Ends
==========================**/
/**=====================
     04. Loader CSS Start
==========================**/
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: #fff;
  z-index: 11;
  top: 0;
}

.loader-wrapper .loader {
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  right: 0;
  margin: 0 auto;
}

.loader-wrapper .loader h4 {
  margin-top: 50px;
  margin-bottom: 0;
}

.loader-wrapper .loader h4 span {
  font-size: 34px;
  color: #FFC107;
}

.loader .line {
  animation: expand 1s ease-in-out infinite;
  border-radius: 10px;
  display: inline-block;
  transform-origin: center center;
  margin: 0 3px;
  width: 1px;
  height: 25px;
}

.loader .line:nth-child(1) {
  background: #00c292;
}

.loader .line:nth-child(2) {
  animation-delay: 180ms;
  background: #ab8ce4;
}

.loader .line:nth-child(3) {
  animation-delay: 360ms;
  background: #FF5370;
}

.loader .line:nth-child(4) {
  animation-delay: 540ms;
  background: #4099ff;
}

@keyframes expand {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(2);
  }
}

.loader-box {
  height: 150px;
  text-align: center;
  display: flex;
  align-items: center;
}

.rotate {
  animation-timing-function: linear;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: rotate;
  border: 3px dotted #ab8ce4;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: block;
  margin: 1em;
  float: left;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.solid {
  border-style: solid;
  border-color: #00c292 #008f6c;
}

.dotted {
  border-style: dotted;
}

.dashed {
  border-style: dashed;
}

.double {
  border-style: double;
}

.groove {
  border-style: groove;
}

.ridge {
  border-style: ridge;
}

.inset {
  border-style: inset;
}

.outset {
  border-style: outset;
}

.colored {
  border-color: #FF5370 #ab8ce4 #00c292 #4099ff;
}

/**=====================
     04. Loader CSS Ends
==========================**/
.testloader {
  padding-left: 200px;
}

.loading {
  position: absolute;
  top: 45%;
  left: 75%;
  transform: translate(-50%, -50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.obj {
  width: 6px;
  height: 40px;
  background: #5b3b97;
  margin: 0 3px;
  border-radius: 10px;
  animation: loading 0.8s infinite;
}

.obj:nth-child(2) {
  animation-delay: 0.1s;
}

.obj:nth-child(3) {
  animation-delay: 0.2s;
}

.obj:nth-child(4) {
  animation-delay: 0.3s;
}

.obj:nth-child(5) {
  animation-delay: 0.4s;
}

.obj:nth-child(6) {
  animation-delay: 0.5s;
}

.obj:nth-child(7) {
  animation-delay: 0.6s;
}

.obj:nth-child(8) {
  animation-delay: 0.7s;
}

@keyframes loading {
  0% {
    height: 0;
  }
  50% {
    height: 40px;
  }
  100% {
    height: 0;
  }
}

.loadertext {
  margin: auto;
  width: 100%;
  padding-top: 70px;
}
