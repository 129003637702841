/**=====================
    26. Form CSS Start
==========================**/
.ngx-form  {
  .f1-buttons  {
    .btn-previous {
      margin-right: 5px;
    }
  }
  .alert {
    color: red;
    padding: 0;
    font-size: 12px;
  }
}
aw-wizard-navigation-bar.horizontal {
  ul.steps-indicator {
    li {
      a {
        .label {
          font-size: 13px !important;
          text-transform: capitalize !important;
          font-weight: 400 !important;
        }
      }
    }
    li.current {
      a {
        .label {
          color: $primary-color;
        }
      }
    }
    li.navigable {
      a {
        .label {
          color: $primary-color;
        }
      }
      &:after {
        background-color: $primary-color !important;
      }
      &:before {
        background-color: $primary-color !important;
      }
    }
  }
  &.large-filled-symbols {
    ul.steps-indicator {
      padding: 60px 0 10px;
      font-size: 18px;
      li.done {
        .step-indicator {
          background-color: $white !important;
          color: $primary-color !important;
          border: 1px solid $primary-color;
        }
      }
      li.current {
        .step-indicator {
          background-color: $primary-color !important;
          color: #fafafa !important;
        }
        &:first-child {
          &:after {
            background-color: $primary-color !important;
          }
        }
      }
      li {
        &:first-child {
          &:after {
            background-color: #e6e6e6;
            content: "";
            position: absolute;
            height: 1px;
            width: 45%;
            top: -25px;
            right: calc(50% + 50px / 2);
          }
        }
        &:last-child {
          &:after {
            background-color: #e6e6e6;
            content: "";
            position: absolute;
            height: 1px;
            width: 45%;
            top: -25px;
            left: calc(50% + 50px / 2);
          }
        }
      }
      li.editing {
        .step-indicator {
          background-color: $primary-color !important;
          color: #fafafa !important;
        }
      }
    }
  }
}
.height-35{
  height: 35px !important;
}
.theme-form {
  input[type=file] {
    height: auto;
  }
  .form-control{
    :focus {
      box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
    }
  }
  &.sm-form{
    .form-group{
      margin-bottom: 14px;
    }
  }
  &.form{
    margin-bottom: -20px;
  }
  .form-group {
    margin-bottom: 20px;
    textarea
    {
      border-color: $light-semi-gray;
      &::-webkit-input-placeholder
      {
        color: $semi-dark;
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: 100;
      }
    }
    input[type=text], input[type=email], input[type=search],
    input[type=password], input[type=number], input[type=tel], input[type=date], input[type=datetime-local]
    , input[type=time], input[type=datetime-local], input[type=month],input[type=week],
    input[type=url],input[type=file], select {
      border-color: $light-semi-gray;
      background-color: $white;
      font-size: 14px;
      color: $dark-gray;
      font-family: $font-open-sans;
      &::-webkit-input-placeholder
      {
        color: $dark-gray;
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: 100;
      }
    }
    select.form-control:not([size]):not([multiple]){
      border-color: $light-semi-gray;
      font-size: 14px;
    }
  }
  .checkbox {
    label {
      padding-left: 10px;
    }
  }
  .form-divider {
    position: relative;
    clear: both;
    height: 0;
    margin: 30px 0;
    border-top: 1px solid $light-semi-gray;
    &:before {
      position: absolute;
      top: -11px;
      left: 40%;
      margin-left: -10px;
      padding: 0 5px;
      content: "";
      color: $theme-font-color;
      background: $white;
    }
  }
  .btn-fb {
    background-color: $fb;
    color: $white;
    width: 100%;
  }
  .btn-twitter {
    background-color: $twitter;
    color: $white;
    width: 100%;
  }
  .btn-google {
    background-color: $google-plus;
    color: $white;
    width: 100%;
  }
}
.form-inline{
  .form-group{
    margin-right: 15px;
    .col-form-label
    {
      margin-right: 5px;
    }
  }
}
.search-form{
  input{
    border: 1px solid $light-semi-gray;
    border-radius: 4px;
    padding: 5px 10px;
    background-repeat: no-repeat;
    background-position: 95%;
  }
}
.form-label-align-right{
  label{
    text-align:right;
    padding-top: 17px;
    font-weight: 500;
  }
}
.custom-file-label{
  line-height: 1.8;
  &::after{
    line-height: 1.8;
  }
}
/**=====================
    26. Form CSS Ends
==========================**/
