// Bootstrap Layout scss
@import '~bootstrap/scss/bootstrap';
@import 'scss/style.scss';
@import 'scss/theme/loader';



.testloader {
    padding-left: 200px;
}

.loading {
    position: absolute;
    top: 45%;
    left: 75%;
    transform: translate(-50%,-50%);
    height: 40px;
    display: flex;
    align-items: center;
  }
  .obj {
    width: 6px;
    height: 40px;
    background: rgb(91, 59, 151);
    margin: 0 3px;
    border-radius: 10px;
    animation: loading 0.8s infinite;
  }
  .obj:nth-child(2){
    animation-delay: 0.1s;
  }
  .obj:nth-child(3){
    animation-delay: 0.2s;
  }
  .obj:nth-child(4){
    animation-delay: 0.3s;
  }
  .obj:nth-child(5){
    animation-delay: 0.4s;
  }
  .obj:nth-child(6){
    animation-delay: 0.5s;
  }
  .obj:nth-child(7){
    animation-delay: 0.6s;
  }
  .obj:nth-child(8){
    animation-delay: 0.7s;
  }
  
  @keyframes loading {
    0%{
      height: 0;
    }
    50%{
      height: 40px;
    }
    100%{
      height: 0;
    }
  }
  
  .loadertext {
    margin: auto;
    width: 100%;
    padding-top: 70px;
  }
  